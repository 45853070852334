/** @jsx jsx */
import React, { useState, useEffect } from 'react';
import { jsx } from '@emotion/core';
import { AppState } from '../redux-modules/root';
import { useHistory, useLocation } from 'react-router-dom';
import { Formik, ErrorMessage, FormikErrors } from 'formik';
import Button from '@material-ui/core/Button';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import PictureAsPdfIcon from '@material-ui/icons/NoteAdd';
import {
    wrap,
    subHeading,
    fieldRow,
    selectField,
    mainContent,
    twoCol,
    inputField,
    label,
    txtDetail,
    backdrop
} from './styles';
import FormGeneratorComponent from './FormGenerator/FormGeneratorComponent';
import { baseApiUrl } from '../api/api';
import * as Types from '../api/definitions';
import { useSelector } from 'react-redux';
import { domainPath } from '../App';
interface DynamicProgramSelectionProps {
    client: Types.Client;
    programList: Types.Program[];
    onProgramSelect: (selected_program: string, selected_name: string) => void;
    onLocationSelect: (data: string[] | any, callBack: (result) => void) => void;
    submitPrediction: (client: Types.Client) => void;
    isLoading: boolean;
    hasError: boolean;
    error: string;
    DynamicQuestions?: any;
    errors: any;
    isEdit: string;
    isPredictionScores: boolean;
    uniqueIdField: string;
}

interface FormValues {
    Program: any;
    Confidence: string;
    Roc_confidence: string;
    client_selected_location: string;
}
const DynamicProgramSelection: React.FC<DynamicProgramSelectionProps> = (props) => {
    const [clientCode, setClientCode] = useState<string | null>(null);
    const history = useHistory();

    const location: any = useLocation();
    const appState = useSelector((state: AppState) => state.user && state.user.user);
   
    const loginPassToken: string = appState ? appState.accessToken + '/' : '';
    return (
        <div css={wrap}>
            <div css={mainContent}>
                <Backdrop css={backdrop} open={props.isLoading}>
                    <CircularProgress color="inherit" />
                </Backdrop>
                {clientCode ? (
                    <div css={fieldRow} style={{ justifyContent: 'flex-end', alignItems: 'center' }}>
                        Prediction program for client {props.client['First Name']} {props.client['Last Name']} has been
                        created with client-id{' '}
                        {Object.keys(props.client).includes('New Client Code')
                            ? props.client['New Client Code']
                            : props.client[`${props.uniqueIdField}`]}
                        .
                        <a
                            css={[txtDetail]}
                            style={{ display: 'flex', marginRight: 15 }}
                            rel="noopener noreferrer"
                            target="_blank"
                            onClick={() => history.push(`/${domainPath}/new-client`)}
                            href={
                                location.state.isPredictionScores
                                    ? `${baseApiUrl}/${domainPath}/index/${clientCode}/${loginPassToken}?is_for_scores=true`
                                    : `${baseApiUrl}/${domainPath}/index/${clientCode}/${loginPassToken}?is_for_scores=false`
                            }
                        >
                            <PictureAsPdfIcon /> Download Report
                        </a>
                    </div>
                ) : (
                    <React.Fragment>
                        <h1 css={subHeading}>FirstMatch&reg; Prediction</h1>
                        <FormGeneratorComponent
                           {...props}
                            client={props.client}
                            DynamicQuestions={props.DynamicQuestions}
                            error={props.error}
                            isEdit={props.isEdit}
                            isPredictionScores={props.isPredictionScores}
                            errors={props.errors}
                            onProgramSelect={props.onProgramSelect}
                            onFormSubmit={props.onLocationSelect}
                            setClientCode={setClientCode}
                        />
                    </React.Fragment>
                )}
                
            </div>
            {/* MAIN CONTENT */}
        </div>
    );
};

export default DynamicProgramSelection;
