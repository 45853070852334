/** @jsx jsx */
import React, { useState, Fragment } from 'react';
import { jsx, css } from '@emotion/core';
import { withRouter, Route, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import Alert from '@material-ui/lab/Alert';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Modal from 'react-modal';
import { domainPath } from './App';
import { fileApiUrl } from './api/api';
import { sendTicket } from './api/api';
import Menu from './components/DynamicMenu/Menu';
import IdleTimerContainer from './containers/IdleTimerContainer';
import { fieldRow, twoCol, inputField, label } from './components/styles';
const App = css`
    margin: 50px auto;
    width: 100%;
    background-color: #fff;
    padding: 16px;
    position: relative;
    @media all and (min-width: 520px) {
        padding: 40px;
        margin: 100px auto;
        width: 60vw;
    }
    @media all and (min-width: 520px) and (max-width: 1024px) {
        width: 90vw;
    }
`;

const AlertBox = css`
    margin: 20px auto;
`;

const nav = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
    @media all and (min-width: 520px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }
`;

const menuButton = css`
    width: 460px;
    height: 50px;
    @media all and (max-width: 520px) {
        width: 70px;
        height: 35px;
        font-size: 6px !important;
    }
    @media all and (max-width: 768px) {
        font-size: 6px;
    }
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    border-radius: 0px !important;
    font-size: 12px;
    color: #9c9c9c;
    @media all and (min-width: 520px) {
        &:not(:last-child) {
            border-right: 2px solid #c4c4c4 !important;
        }
    }
`;

const logo = css`
    position: relative;
    height: 100px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
`;

const firstMatchLogo = css`
    //position: absolute;
    // transform: translate(-50%, -30%);
    left: 35%;
    top: -25px;
    // height: auto;
    width: 100%;
    height: 100%;
    object-fit: contain;
    @media all and (max-width: 520px) {
        width: 80px;
    }
`;

const btn_container = css`
    position: fixed;
    top: 50%;
    right: 0px;
    width: 115px;
`;

const fixed_button = css`
    transform: rotate(-90deg);
    -webkit-transform: rotate(-90deg);
    -moz-transform: rotate(-90deg);
    -o-transform: rotate(-90deg);
    display: block;
    margin-left: 10px;
    text-align: center;
    width: 165px;
    padding: 8px 16px;
    background-color: #8f00ff;
    color: #fff;
    border: 2px solid #8f00ff;
    border-radius: 3px;
    font-family: Arial, sans-serif;
    font-size: 17px;
    font-weight: bold;
    text-decoration: none;
    border-radius: 4px;
`;
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        height: '50%',
        width: '50%',
        transform: 'translate(-20%, -50%)'
    }
};

const AppShell: React.FC = ({ children }, props) => {

    const { pwd_expires_in, is_pwd_expired, is_dashboard_published, accessToken, role_type } =
        children && children[1].props.appState.user.user;

    const is_configured = children && children[1].props.appState.user.user.is_fully_configured;
    const logopath = children && children[1].props.appState.user.user.logo_path;
    const orgType = children && children[1].props.appState.user.user.org_type;
    const headerColor = children && children[1].props.appState.user.user.header_color;
    const org_type = children && children[1].props.appState.user.user.org_type;
    const referral_info = children && children[1].props.appState.user.user.referral_info;
    const billing_status = children && children[1].props.appState.user.user.billing_status;
    const [isOpen, setisOpen] = useState(false);
    const [selectedFile, setselectedFile] = useState();
    const [subject, setSubject] = useState('');
    const [description, setDescription] = useState('');
    const [show, setShow] = useState(false);
    const history = useHistory();

    const handleClose = () => {
        setisOpen(false);
        setSubject('');
        setDescription('');
    };

    const isOpenModal = () => {
        setisOpen(true);
    };
    const onFileChange = (event) => {
        setselectedFile(event.target.files);
    };
    const onSubmitTicket = async () => {
        let files: any = selectedFile;
        const formData = new FormData();
        formData.append('subject', subject);
        formData.append('description', description);

        if (files) {
            for (let i = 0; i < files.length; i++) {
                formData.append(`attachments`, files[i]);
            }
        }

        const res = await sendTicket(formData);
        if (res.message === 'your ticket raised') {
            setisOpen(false);
            setSubject('');
            setDescription('');
            alert(res.message);
        } else {
            alert(res.attachments ? res.attachments[0].errors : res.message);
        }
    };
    const screenName = window.location.pathname.split('/').pop();
    return (
        <Paper css={App} elevation={3}>
            <IdleTimerContainer />
            {accessToken && pwd_expires_in <= 10 ? (
                show ? (
                    ''
                ) : (
                    <Alert css={AlertBox} onClose={() => setShow(true)} severity={is_pwd_expired ? 'error' : 'warning'}>
                        {' '}
                        <span>

                            Your password {is_pwd_expired ? 'has expired' : `will expire in ${pwd_expires_in} days`}.

                            {screenName === 'changepassword' ? (
                                ''
                            ) : (
                                <React.Fragment>
                                    &nbsp;Please{' '}
                                    <Link
                                        onClick={() => {
                                            history.push(`/${domainPath}/changepassword`);
                                        }}
                                    >
                                        <strong>
                                            <u style={{ color: 'red' }}>click here</u>
                                        </strong>{' '}
                                    </Link>
                                    to change your password!
                                </React.Fragment>
                            )}
                        </span>
                    </Alert>
                )
            ) : (
                ''
            )}

            <div css={logo}>
                <img css={firstMatchLogo} alt={`${domainPath} Logo`} src={`${fileApiUrl}/${logopath}`} />
                
            </div>
            <Menu
                is_configured={is_configured}
                is_pwd_expired={is_pwd_expired}
                domainPath={domainPath}
                headerColor={headerColor}
                role_type={role_type}
                org_type={orgType}
                is_dashboard_published={is_dashboard_published}
                referral_info={referral_info}
                billing_status={billing_status}
            />
            <div style={{ position: 'absolute' }}>
                <div css={btn_container}>
                    <button onClick={isOpenModal} css={fixed_button} style={{ position: 'fixed' }}>
                        Need Help?
                    </button>
                </div>
            </div>
            <Modal
                isOpen={isOpen}
                ariaHideApp={false}
                onRequestClose={handleClose}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div css={fieldRow}>
                    <div css={twoCol}>
                        <label css={label}>Subject:</label>
                        <input
                            type="text"
                            name="first_name"
                            css={inputField}
                            placeholder=""
                            value={subject}
                            onChange={(e) => setSubject(e.target.value)}
                            required
                        />
                        {/* <ErrorMessage component="span" name="first_name" /> */}
                    </div>
                </div>
                <div css={fieldRow}>
                    <div css={twoCol}>
                        <label css={label}>Description:</label>
                        <textarea
                            name="first_name"
                            css={inputField}
                            placeholder="Please provide as many details as you can, like the client code, page you are on, actions you took and the problemy you faced. Please attach a file or multiple files."
                            style={{ height: '150px' }}
                            value={description}
                            onChange={(e) => setDescription(e.target.value)}
                            required
                        />
                        {/* <ErrorMessage component="span" name="first_name" /> */}
                    </div>
                </div>
                <div css={fieldRow}>
                    <div css={twoCol}>
                        <input type="file" name="uploadfiles" multiple onChange={onFileChange} />
                    </div>
                    <div css={twoCol}>&nbsp;</div>
                    <div css={twoCol}>
                        <Button type="submit" size="large" variant="contained" color="primary" onClick={onSubmitTicket}>
                            Submit
                        </Button>
                    </div>
                </div>
            </Modal>

            {children}
        </Paper>
    );
};

export default withRouter(AppShell);
