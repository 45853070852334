/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { inputField } from '../components/styles';

const InputField = ({ data, sectionIndex, questionIndex, state, preventCopyPaste, handleChange, updateLocalState, keyUp, disableField, configType }) => {
    const dataState = Array.isArray(state) ? state.length > 0 ? state[sectionIndex] && state[sectionIndex][data.question] : '' : state.client_form[data.question] || state.client_form[data.question.replace(/ /g, '_')];
    
    return (
        <React.Fragment>
            <input
                css={inputField}
                data-idx={sectionIndex}
                data-idy={questionIndex}
                name={data.question}
                value={dataState}
                type={data.answer_type.toLowerCase()}
                onBlur={(e)=>keyUp(e, state, data, configType)}
                placeholder=""
                onChange={(e) => handleChange(e, data, sectionIndex, questionIndex, state, updateLocalState, '' )}
                onCopy={(e) => preventCopyPaste(e)}
                onPaste={(e) => preventCopyPaste(e)}
                onCut={(e) => preventCopyPaste(e)}
                disabled={disableField}
            />
        </React.Fragment>
    );
};

export default InputField;
