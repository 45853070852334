/** @jsx jsx */
import React, { Fragment } from 'react';
import { withRouter, Route, useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';
import { jsx, css } from '@emotion/core';
import { menuData } from './MenuItems';

interface MenuNavProps {
    is_configured: boolean;
    is_pwd_expired: boolean;
    domainPath: string;
    headerColor: string;
    role_type: string;
    org_type: number;
    is_dashboard_published: string;
    referral_info: any;
    billing_status: boolean;
}

const nav = css`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 30px;
    @media all and (min-width: 520px) {
        flex-direction: row;
        align-items: flex-start;
        justify-content: center;
    }
`;

const menuButton = css`
    width: 460px;
    height: 50px;
    @media all and (max-width: 520px) {
        width: 70px;
        height: 35px;
        font-size: 6px !important;
    }
    @media all and (max-width: 768px) {
        font-size: 6px;
    }
    cursor: pointer;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    text-transform: uppercase;
    display: flex;
    flex-direction: column;
    border-radius: 0px !important;
    font-size: 12px;
    color: #9c9c9c;
    @media all and (min-width: 520px) {
        &:not(:last-child) {
            border-right: 2px solid #c4c4c4 !important;
        }
    }
`;

const Menu = (props: MenuNavProps) => {
    const filteredOrg = menuData.filter((item) => item.id === props.org_type);
    const values = {
        is_configured: props.is_configured,
        is_pwd_expired: props.is_pwd_expired,
        role_type: props.role_type,
        is_dashboard_published: props.is_dashboard_published,
        domainPath: props.domainPath,
        referral_info: props.referral_info,
        billing_status: props.billing_status
    };

    const menuItems = filteredOrg[0]?.menu_list.filter((item) => (item.checkCondition ? item.condition(values) : item));
    const menuList = ["New", "Existing"];
    return (
        <div css={nav}>
            {menuItems?.map((item, index) => {
                let link = item.link;
                let name =item.name;
                if(item.hasOwnProperty('referralType')) {
                    name = item.referralType ? props.referral_info[item.referralType] ? `${item.name?.split(" ")?.shift()} ${props.referral_info[item.referralType]}` : name : name;
                    link = item.referralType ? props.referral_info[item.referralType] ? `${item.link?.split(" ")?.shift()}${props.referral_info[item.referralType]?.toLowerCase()}` : link : link;
                } 
                return (
                <Fragment>
                    <Route
                        key={index}
                        path={`/${props.domainPath}/${item.link}`}
                        // exact={activeOnlyWhenExact}
                        children={({ match, history }) => (
                            <Link
                                onClick={() => {
                                    history.push(`/${props.domainPath}/${item.link}`);
                                }}
                                css={menuButton}
                                style={
                                    match
                                        ? { backgroundColor: '#f5f5f5', color: '#9d9d9d' }
                                        : { backgroundColor: props.headerColor, color: 'white' }
                                }
                            >
                                {props.is_configured === true ? `${name}` : ''}
                            </Link>
                        )}
                    />
                </Fragment>
            )})}
        </div>
    );
};

export default Menu;