/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import { KeyUpService } from '../Services/KeyUpService';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    root: {
        '& .MuiInputBase-root': {
          backgroundColor: '#f0f0f0',
          borderRadius: 0,
          height: '45px'
        },
        '& .MuiOutlinedInput-notchedOutline': {
          borderColor: '#4caf50',
        },
        '&:hover .MuiOutlinedInput-notchedOutline': {
          borderColor: '#388e3c',
        },
        '&.Mui-focused .MuiOutlinedInput-notchedOutline': {
          borderColor: '#1b5e20',
        },
        '& .MuiInputLabel-root': {
          color: '#4caf50',
        },
        '&.Mui-focused .MuiInputLabel-root': {
          color: '#1b5e20',
        },
        '& .MuiSvgIcon-root': {
          color: '#4caf50',
        },
        '& .MuiFilledInput-input': {
            padding: '20px 8px 5px',
        },
      },
}));

const DateField = ({ data, sectionIndex, questionIndex, state, validateDateFormate, handleDatePicker, updateLocalState, DynamicQuestions, disableField }) => {
     const dataState =  Array.isArray(state) ? state.length > 0 && state[sectionIndex] && state[sectionIndex][data?.question] :  state.client_form[data.question.replace(/ /g, '_')] || state.client_form[data.question];
     const classes = useStyles();
    return (
        <KeyboardDatePicker
                clearable
                name={data.question}
                value={
                    dataState
                        ? new Date(dataState)
                        : null
                }
                maxDate={new Date('9998-12-31')}
                maxDateMessage={`${data.question} should not be after maximal date ${validateDateFormate(
                    '9998-12-31'
                )}`}
                minDate={
                    data.question === 'Date of Birth'
                        ? new Date('1900-01-01')
                        : dataState
                        ? new Date(dataState)
                        : new Date('1900-01-01')
                }
                minDateMessage={
                    data.question === 'Date of Birth'
                        ? `${data.question} should not be before *minimal date - ${validateDateFormate(
                              new Date('1900-01-01')
                          )}*`
                        : dataState
                        ? `${data.question} should not be before *Date of Birth - ${validateDateFormate(
                              dataState
                          )}*`
                        : `${data.question} should not be before *minimal date - ${validateDateFormate(
                              new Date('1900-01-01')
                          )}*`
                }
                onChange={(date) => handleDatePicker(sectionIndex, questionIndex, date, state, data, updateLocalState)}
                format="MM/dd/yyyy"
                placeholder="mm/dd/yyyy"
                autoOk={true}
                allowKeyboardControl={true}
                animateYearScrolling={true}
                inputVariant="filled"
                orientation="landscape"
                onBlur={(e) => state.hasOwnProperty('client_form') && KeyUpService.handleKeyUp(e, state, data, 1)}
                disabled={disableField}
                className={classes.root}
            />
    );
};

export default DateField;
