/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { useState, useEffect, Fragment } from 'react';
import { AppState } from '../redux-modules/root';
import { useParams, Link } from 'react-router-dom';
import Modal from 'react-modal';
import { Formik, ErrorMessage, FormikErrors } from 'formik';
import Button from '@material-ui/core/Button';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Backdrop from '@material-ui/core/Backdrop';
import CircularProgress from '@material-ui/core/CircularProgress';
import PictureAsPdfIcon from '@material-ui/icons/PictureAsPdf';
import { useSnackbar } from 'notistack';
import * as Utility from "../Common/Base";
// import * as referral from "../redux-modules/referral";
// import ReferralList from "../components/ReferralList";
import {
    label,
    backdrop,
    selectField,
    subHeading,
    fieldRow,
    inputField,
    twoCol,
    txtLabel,
    panel,
    panelHeading,
    panelHeader,
    txtDetail,
    fieldBox,
    flexBetween,
    dangerLabel,
    textArea_50,
    textArea
} from './styles';
import Dropdown from './Dropdown';
import * as Types from '../api/definitions';
import { baseApiUrl } from '../api/api';
import { useSelector } from 'react-redux';
import { domainPath } from '../App';
import ModifyHardSaveData from './StaticOrgFeatures/ModifyHardSaveData';
import { getModifyHardSave } from '../api/api';
const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -110%)'
    }
};
interface DynamicClientDetailsAdelphoiProps {
    client: any;
    index: any;
    onProgramSelect: (client_code: string, selected_program: string, values: any) => Promise<void>;
    onVersionSelect: (client_code: string, version: string) => Promise<void>;
    onFormSubmit: (
        client_code: string,
        program_completion: number | null,
        returned_to_care: number | null,
        Remained_Out_of_Care: number | null,
        program_significantly_modified: number,
        Program: string | null,
        Location: string | null,
        start_date: any | null,
        end_date: any | null,
        referral_status: any | null,
        roc_confidence: any,
        confidence: any,
        db_row_id: string | null,
        comment: string | null
        //Referral: string | null
    ) => void;
    isLoading: boolean;
    hasError: boolean;
    error: string;
    searchData: any;
    program_completion_response: string | null;
    // getReferral: () => Promise<void>;
    Referral: Types.Referral[];
    is_role_type: string;
    is_prediction_available: string;
}

interface FormValues {
    Program_Completion: string | number | any;
    Returned_to_Care: string | number | null;
    Remained_Out_of_Care: string | number | null;
    program_significantly_modified: number | string | null;
    Program: any;
    start_date: string;
    end_date: string;
    referral_status: any;
    confidence: number;
    roc_confidence: number;
    Location: any;
    Referral: any;
}

const DynamicClientDetailsAdelphoi: React.FC<DynamicClientDetailsAdelphoiProps> = (props) => {
    const [modalIsOpen, setIsOpen] = useState(false);
    const [predicted_program, setPredictedProgram] = useState<string | null>(null);
    const [predicted_referral, setPredictedReferral] = useState<string | null>(null);
    const [referralStatus, setReferralStatus] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [predicted_location, setPredictedLocation] = useState<string | null>(null);

    const [SelectedVersion, setSelectedVersion] = useState<any | null>(null);
    const appState = useSelector((state: AppState) => state.user && state.user.user);

    const [version_changed, setVersion] = useState<any | null>(null);
    const [modifyOutcome, setModifyOutcome] = useState<string | null>('');
    const [comment, setComment] = useState<string | null>('');
    const { enqueueSnackbar } = useSnackbar();
    function openModal() {
        setIsOpen(true);
    }

    function closeModal() {
        setIsOpen(false);
    }
    const { client, searchData, is_role_type } = props;
    useEffect(() => {
        if (!SelectedVersion || SelectedVersion === props.searchData) {
            const length = props.searchData.length - 1;
            setSelectedVersion([searchData[length]]);
        }
    }, [SelectedVersion, props.searchData, searchData]);
    useEffect(() => {
        if (!predicted_program || predicted_program === props.client.selected_program) {
            setPredictedProgram(props.client.model_program);
        }
    }, [predicted_program, props.client.model_program, props.client.selected_program]);
    useEffect(() => {
        if (!predicted_referral || predicted_referral === props.client.selected_referral) {
            setPredictedReferral(props.client.selected_referral);
        }
    }, [predicted_referral, props.client.selected_referral]);

    useEffect(() => {
        if (!predicted_location || predicted_location === props.client.selected_location) {
            setPredictedLocation(props.client.selected_location);
        }
    }, [predicted_location, props.client.selected_location]);


    const onProgramChange = (program: any, values: any) => {
        setReferralStatus(values.referral_status);
        props.onProgramSelect(props.searchData[0].client_code!, program.value, values);
    };

    let { index } = useParams<{ index: string }>();
    if (!index) {
        return <h1 css={subHeading}>No client found</h1>;
    }
    // const { client, Referral, searchData } = props;
    let suggested_locations: any =
        props.client.SuggestedLocations?.length > 0
            ? props.client.SuggestedLocations
            : props.client['Suggested Locations'];
    const programOptions = props.client.SuggestedPrograms
        ? props.client.SuggestedPrograms.map((p) => {
              return {
                  label: p,
                  value: p,
                  predicted: p === predicted_program,
                  id: p
              };
          })
        : [];
    const locationOptions = suggested_locations
        ? suggested_locations.map((l) => {
              return {
                  label: l,
                  value: l,
                  predicted: l === predicted_location,
                  id: l
              };
          })
        : [];
    const getInitialValues = (): FormValues => {
        const { client } = props;
        let program: any = null;
        let location: any = null;
        let referral: any = null;
        if (client.selected_referral) {
            referral = {
                label: client.selected_referral,
                value: client.selected_referral,
                predicted: client.selected_referral === predicted_referral
            };
        }
        if (client.selected_program) {
            program = {
                label: client.selected_program,
                value: client.selected_program,
                predicted: client.selected_program === predicted_program
            };
        } else if (client.model_program) {
            program = {
                label: client.model_program,
                value: client.model_program,
                predicted: client.selected_program === predicted_program
            };
        }
        if (client.selected_location) {
            location = {
                label: client.selected_location,
                value: client.selected_location,
                predicted: client.selected_location === predicted_location
            };
        }
        
        return {
            Program_Completion:
                client.Program_Completion === null
                    ? ''
                    : client.Program_Completion && client.Program_Completion.toString(),
            Remained_Out_of_Care:
                client.Remained_Out_of_Care === null
                    ? ''
                    : client.Remained_Out_of_Care && client.Remained_Out_of_Care.toString(),

            Returned_to_Care:
                client.Returned_to_Care === null ? '' : client.Returned_to_Care && client.Returned_to_Care.toString(),
            program_significantly_modified: Number(client.program_significantly_modified),
            roc_confidence: client.client_selected_roc_confidence
                ? client.client_selected_roc_confidence
                : client.client_selected_roc_confidence === 0
                ? 0
                : client.roc_confidence,
            Program: program,
            Referral: referral,

            confidence: client.client_selected_confidence
                ? client.client_selected_confidence
                : client.client_selected_confidence === 0
                ? 0
                : client.confidence,
            Location: location || '',

            start_date: client.start_date  ? (startDate ? Utility.validateDateFormate(startDate) : Utility.validateDateFormate(client.start_date)) : '',

            end_date: client.end_date  ? (endDate ? Utility.validateDateFormate(endDate) : Utility.validateDateFormate(client.end_date)) : '',

            referral_status:
                client.referral_status !== null ? (referralStatus ? referralStatus : client.referral_status) : ''
        };
    };

    const handleChange = (e) => {
        const { value } = e.target;
        var optionElement = e.target.childNodes[e.target.selectedIndex];
        let version_id = optionElement.getAttribute('data-id');
        const val = searchData.filter((sec, i) => sec.version === value && sec);
        setModifyOutcome('');
        setSelectedVersion(val);
        setVersion(!version_id ? false : true);
        setPredictedProgram(val[0].client_selected_program);
        setPredictedReferral(val[0].selected_referral);
        setPredictedLocation(val[0].client_selected_locations);
        setReferralStatus(val[0].referral_status);
        setStartDate(val[0].start_date);
        setEndDate(val[0].end_date);
        props.onVersionSelect(props.searchData[0].client_code, version_id);
    };

   

    const display = (id, ver) => {
        const tempArray = [] as any;
        let questions = SelectedVersion && SelectedVersion[0].sections[id].questions;
        const length: any = questions && questions.length;
        for (let i = 0; i < length; i++) {
            if ((i + 1) % 2 !== 0) {
                var tempArray1 = [] as any;
                tempArray1.push(questions[i]);
                if (length === i + 1) {
                    tempArray.push(tempArray1);
                }
            } else {
                tempArray1.push(questions[i]);
                tempArray.push(tempArray1);
            }
        }
        return tempArray;
    };
    const is_date = function (date) {
        let dateObj: any = new Date(date.replace(/-/g, '/'));
        const regex = new RegExp(
            '([0-9]{4}[-](0[1-9]|1[0-2])[-]([0-2]{1}[0-9]{1}|3[0-1]{1})|([0-2]{1}[0-9]{1}|3[0-1]{1})[-](0[1-9]|1[0-2])[-][0-9]{4})'
        );
        if (regex.test(date)) {
            let year = dateObj.getFullYear();
            let month = 1 + dateObj.getMonth();
            let datee = dateObj.getDate();
            let date1 = [month.toString().length > 1 ? month : `0${month}`, datee, year].join('-');

            return date1;
        }
        return date;
    };
    const downloadModifyHardSave = async (e) => {
        e.preventDefault();
        const is_accessToken: any = appState ? appState.accessToken : '';
        const response = await getModifyHardSave(is_accessToken, SelectedVersion[0]['_id']);
        enqueueSnackbar(response.message);
    };
    const latestVersion = searchData[searchData.length - 1];
    
    return (
        <div>
            <Backdrop css={backdrop} open={props.isLoading}>
                <CircularProgress color="inherit" />
            </Backdrop>

            <div css={fieldRow}>
                <div css={twoCol}>
                    {['Super Admin', 'Tester'].includes(is_role_type) ? (
                        <Button
                            type="submit"
                            size="medium"
                            variant="contained"
                            style={{
                                marginRight: 10,
                                backgroundColor: appState?.header_color,
                                color: '#fff',
                                textTransform: 'capitalize'
                            }}
                            css={txtDetail}
                            onClick={downloadModifyHardSave}
                        >
                            <PictureAsPdfIcon />
                            Download Updated History
                        </Button>
                    ) : (
                        <Fragment>&nbsp;</Fragment>
                    )}
                </div>
                <div css={twoCol}>
                    <div css={txtDetail}>
                        <select css={selectField} name="versions" onChange={handleChange}>
                            <option value="">Select Version</option>
                            {searchData.map((data, i) => (
                                <option
                                    key={i}
                                    data-id={searchData.length - 1 === i ? '' : i}
                                    value={data.version}
                                    selected={data.version === searchData[searchData.length - 1].version}
                                >
                                    {data.version}&nbsp; - &nbsp;{is_date(data.date_created)}
                                </option>
                            ))}
                        </select>
                    </div>
                </div>
                {!props.is_prediction_available ? (
                    ''
                ) : (
                    <div
                        css={twoCol}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            textAlign: 'center',
                            paddingTop: 13,
                            paddingBottom: 20
                        }}
                    >
                        <a
                            rel="noopener noreferrer"
                            target="_blank"
                            css={txtDetail}
                            href={`${baseApiUrl}/${domainPath}/index/${client.client_code}/${
                                appState ? appState.accessToken + '/' : ''
                            }`}
                        >
                            <PictureAsPdfIcon /> Download Report
                        </a>
                    </div>
                )}
            </div>

            {SelectedVersion &&
                SelectedVersion[0].sections.map(
                    (ques, id) =>
                        (ques.section !== 'Outcomes' && ques.related === 'false') && (
                            <Accordion defaultExpanded={id === 0 ? true : false} key={id}>
                                <AccordionSummary
                                    css={panelHeader}
                                    expandIcon={<ExpandMoreIcon />}
                                    aria-controls="panel1a-content"
                                >
                                    <h1 css={panelHeading}>{ques.section}</h1>
                                </AccordionSummary>
                                <AccordionDetails css={panel}>
                                    {display(id, SelectedVersion).map((item, index) => {
                                        return (
                                            <div css={fieldRow} key={index}>
                                                {item.map((ques, index_1) => {
                                                    return (
                                                        <div css={twoCol} key={index_1}>
                                                            <label css={txtLabel}>{ques.question}</label>
                                                            <div css={txtDetail}>
                                                                {Array.isArray(ques.answer)
                                                                    ? ques.answer.toString()
                                                                    : ques.answer.toString().includes('-')
                                                                    ? is_date(ques.answer)
                                                                    : ques.question === 'Age'
                                                                    ? props.searchData[0].ageAtEpisodeStart
                                                                        ? props.searchData[0].ageAtEpisodeStart
                                                                        : ques.answer
                                                                    : ques.answer}
                                                            </div>
                                                        </div>
                                                    );
                                                })}
                                            </div>
                                        );
                                    })}
                                </AccordionDetails>
                            </Accordion>
                        )
                )}
            <div css={flexBetween}>
                {['not_placed', 'rejected', 'rescinded'].includes(latestVersion.referral_status) ||
                latestVersion.Remained_Out_of_Care?.toString() ? (
                    <h3>
                        {' '}
                        Click{' '}
                        <Link
                            to={{
                                pathname: `/${domainPath}/existing-client/edit-details/${index}&true&true`,
                                state: { isPredictionScores: false }
                            }}
                        >
                            <u style={{ color: 'red' }}>here</u>
                        </Link>{' '}
                        to Re-Refer the Client.
                    </h3>
                ) : latestVersion.referral_status !== 'pending' ? (
                    latestVersion.Program_Completion === null || latestVersion.Program_Completion === '' ? (
                        <h3>
                            <Link
                                to={{
                                    pathname: `/${domainPath}/existing-client/edit-details/${index}&true&false`,
                                    state: { isPredictionScores: true }
                                }}
                            >
                                <u style={{ color: 'red' }}>Get Transfer Prediction</u>
                            </Link>
                        </h3>
                    ) : (
                        ''
                    )
                ) : (
                    <h3>
                        Click{' '}
                        <Link
                            to={{
                                pathname: `/${domainPath}/existing-client/edit-details/${index}&true&false`,
                                state: { isPredictionScores: false }
                            }}
                        >
                            <u style={{ color: 'red' }}>here</u>
                        </Link>{' '}
                        to update the Client details.
                    </h3>
                )}

                {SelectedVersion && ['pending'].includes(SelectedVersion[0].referral_status) ? (
                    ''
                ) : (
                    <h3>
                        <ModifyHardSaveData
                            client={props.index}
                            is_role_type={is_role_type}
                            SelectedVersion={SelectedVersion && SelectedVersion[0]}
                            modifyOutcome={modifyOutcome}
                            setModifyOutcome={setModifyOutcome}
                            orgType={1}
                            latestVersion={latestVersion}
                        />
                    </h3>
                )}
            </div>
            <Formik
                initialValues={getInitialValues()}
                enableReinitialize
                validate={(values) => {
                    const errors: FormikErrors<FormValues> = {};

                    if (values.Program !== predicted_program) {
                        if (!values.Location) {
                            if (values.referral_status === 'placed') {
                                errors.Location = 'Required';
                            }
                        }
                        if (!values.referral_status) {
                            errors.referral_status = 'Required';
                        }
                        if (values.referral_status === 'placed') {
                            if (!values.start_date) {
                                errors.start_date = 'Required';
                            }
                        }
                    }

                    if (
                        values.Program_Completion === '0' ||
                        values.Program_Completion === '1' ||
                        values.Program_Completion === '2'
                    ) {
                        if (!values.end_date) {
                            errors.end_date = 'Required';
                        }
                    }

                    if (values.referral_status?.toLowerCase() !== 'placed') {
                        values.Program_Completion = '';
                        values.Returned_to_Care = '';
                        values.Remained_Out_of_Care = '';
                        values.program_significantly_modified = null;
                        values.start_date = '';
                        values.end_date = '';
                        values.Referral = '';
                    }
                    return errors;
                }}
                onSubmit={async (values, helpers) => {
                    if (values.referral_status?.toLowerCase() !== 'pending' && !modalIsOpen) {
                        await openModal();
                    } else {
                        if (!searchData[0].client_code) {
                            return false;
                        }
                        const Program_Completion =
                            values.Program_Completion === '' ? null : Number(values.Program_Completion);
                        const Remained_Out_of_Care =
                            values.Remained_Out_of_Care === '' ? null : Number(values.Remained_Out_of_Care);
                        const Returned_to_Care =
                            values.Returned_to_Care === '' ? null : Number(values.Returned_to_Care);
                        const start_date =
                            values.start_date === ''
                                ? null
                                : values.referral_status !== 'placed'
                                ? null
                                : values.start_date;
                        const end_date = values.end_date === '' ? null : values.end_date;
                        const referral_status = values.referral_status === '' ? null : values.referral_status;
                        const location = values.Location === '' ? null : values.Location.value;
                        setIsOpen(false);
                        let db_row_id: string = modifyOutcome ? modifyOutcome : '';
                        await props.onFormSubmit(
                            searchData[0].client_code,
                            Program_Completion,
                            Returned_to_Care,
                            Remained_Out_of_Care,
                            Number(values.program_significantly_modified),
                            values.Program!.value!,
                            location,
                            start_date,
                            end_date,
                            referral_status,
                            Number(values.confidence),
                            Number(values.roc_confidence),

                            db_row_id,
                            comment
                            // values.Referral!.value!,
                            //values.roc_confidence!.value!
                        );
                        setSelectedVersion((prevState) => [
                            {
                                ...prevState[0],
                                ['referral_status']: referral_status
                            }
                        ]);
                        setModifyOutcome('');
                        helpers.resetForm();
                    }
                }}
            >
                {({ values, handleSubmit, handleChange }) => (
                    <form name="clientDetailsForm" onSubmit={handleSubmit} style={{ marginTop: 20 }}>
                        <div css={fieldRow}>
                            <div css={twoCol}>
                                <label css={label} htmlFor="program_significantly_modified">
                                    Referral Status
                                </label>
                            </div>
                            <div css={twoCol}>
                                <div css={fieldBox}>
                                    <input
                                        type="radio"
                                        onChange={handleChange}
                                        disabled={
                                            modifyOutcome
                                                ? false
                                                : version_changed || props.client.referral_status !== 'pending'
                                        }
                                        name="referral_status"
                                        value="pending"
                                        checked={
                                            values.referral_status !== null
                                                ? values.referral_status === 'pending'
                                                : false
                                        }
                                    />
                                    <label>Pending</label>
                                </div>
                                <div css={fieldBox}>
                                    <input
                                        type="radio"
                                        onChange={handleChange}
                                        name="referral_status"
                                        value="placed"
                                        disabled={
                                            modifyOutcome
                                                ? false
                                                : version_changed || props.client.referral_status !== 'pending'
                                        }
                                        checked={
                                            values.referral_status !== null
                                                ? values.referral_status === 'placed'
                                                : false
                                        }
                                    />
                                    <label>Placed</label>
                                </div>
                                <div css={fieldBox}>
                                    <input
                                        type="radio"
                                        onChange={handleChange}
                                        disabled={
                                            modifyOutcome
                                                ? false
                                                : version_changed || props.client.referral_status !== 'pending'
                                        }
                                        name="referral_status"
                                        value="not_placed"
                                        checked={
                                            values.referral_status !== null
                                                ? values.referral_status === 'not_placed'
                                                : false
                                        }
                                    />
                                    <label>Accepted but not placed</label>
                                </div>
                                <div css={fieldBox}>
                                    <input
                                        type="radio"
                                        onChange={handleChange}
                                        disabled={
                                            modifyOutcome
                                                ? false
                                                : version_changed || props.client.referral_status !== 'pending'
                                        }
                                        name="referral_status"
                                        value="rejected"
                                        checked={
                                            values.referral_status !== null
                                                ? values.referral_status === 'rejected'
                                                : false
                                        }
                                    />
                                    <label>Rejected</label>
                                </div>

                                <div css={fieldBox}>
                                    <input
                                        type="radio"
                                        onChange={handleChange}
                                        disabled={
                                            modifyOutcome
                                                ? false
                                                : version_changed || props.client.referral_status !== 'pending'
                                        }
                                        name="referral_status"
                                        value="rescinded"
                                        checked={
                                            values.referral_status !== null
                                                ? values.referral_status === 'rescinded'
                                                : false
                                        }
                                    />
                                    <label>Rescinded</label>
                                </div>

                                <ErrorMessage component="span" name="referral_status" />
                            </div>
                        </div>
                        <div css={fieldRow}>
                            <div css={twoCol}>
                                <label css={label}>Program</label>
                            </div>
                            <div css={twoCol}>
                                <Dropdown
                                    name="Program"
                                    disabled={
                                        modifyOutcome
                                            ? false
                                            : version_changed
                                            ? version_changed
                                            : props.client.referral_status !== 'pending'
                                            ? true
                                            : values.Program_Completion !== ''
                                    }
                                    options={programOptions}
                                    onChange={(p: any) => onProgramChange(p, values)}
                                    defaultValue={programOptions.find((p) => p.value === predicted_program)}
                                    value={values.Program || null}
                                />
                            </div>
                        </div>
                        <div css={fieldRow}>
                            <div css={twoCol}>
                                <label css={label}>Location </label>
                            </div>
                            <div css={twoCol}>
                                <Dropdown
                                    name="Location"
                                    disabled={
                                        modifyOutcome
                                            ? false
                                            : version_changed
                                            ? version_changed
                                            : props.client.referral_status !== 'pending'
                                            ? true
                                            : values.Program_Completion !== ''
                                    }
                                    options={locationOptions}
                                    // onChange={(p: any) => onLocationChange(p, values)}
                                    defaultValue={locationOptions.find((l) => l.value === predicted_location)}
                                    value={values.Location || null}
                                />
                            </div>
                        </div>
                        {values.referral_status === 'placed' && (
                            <div css={fieldRow}>
                                <div css={twoCol}>
                                    <label css={label}>Start Date</label>
                                </div>
                                <div css={twoCol}>
                                    <input
                                        type="date"
                                        name="start_date"
                                        disabled={
                                            modifyOutcome
                                                ? false
                                                : version_changed || props.client.referral_status !== 'pending'
                                        }
                                        css={inputField}
                                        placeholder=""
                                        value={values.start_date || ''}
                                        min={
                                            SelectedVersion &&
                                            SelectedVersion[0].sections[0].questions.find(
                                                (ques) => ques.question === 'Date of Referral'
                                            )?.answer
                                        }
                                        onWheel={({ target }) => (target as HTMLInputElement).blur()}
                                        onChange={handleChange}
                                    />
                                    
                                    <ErrorMessage component="span" name="start_date" />
                                </div>
                            </div>
                        )}
                        <div css={fieldRow}>
                            <div css={twoCol}>
                                <label css={label}>Program Completion Likelihood</label>
                            </div>
                            <div css={twoCol}>
                                <input
                                    type="text"
                                    name="confidence"
                                    readOnly
                                    disabled={
                                        modifyOutcome
                                            ? false
                                            : version_changed || props.client.referral_status !== 'pending'
                                    }
                                    css={inputField}
                                    // disabled={Number(values.Program_Completion) === 0}
                                    placeholder=""
                                    value={values.confidence ? values.confidence : values.confidence === 0 ? 0 : ''}
                                    onChange={handleChange}
                                />
                                <ErrorMessage component="span" name="confidence" />
                            </div>
                        </div>
                        <div css={fieldRow}>
                            <div css={twoCol}>
                                <label css={label}>Remain Out of Care Likelihood</label>
                            </div>
                            <div css={twoCol}>
                                <input
                                    type="text"
                                    name="roc_confidence"
                                    readOnly
                                    disabled={
                                        modifyOutcome
                                            ? false
                                            : version_changed || props.client.referral_status !== 'pending'
                                    }
                                    css={inputField}
                                    // disabled={Number(values.Program_Completion) === 0}
                                    placeholder=""
                                    value={
                                        values.roc_confidence
                                            ? values.roc_confidence
                                            : values.roc_confidence === 0
                                            ? 0
                                            : ''
                                    }
                                    onChange={handleChange}
                                />
                                <ErrorMessage component="span" name="roc_confidence" />
                            </div>
                        </div>
                        {values.referral_status === 'placed' && (
                            <div css={fieldRow}>
                                <div css={twoCol}>
                                    <label css={label}>Program Completion</label>
                                </div>
                                <div css={twoCol}>
                                    <select
                                        css={selectField}
                                        onChange={handleChange}
                                        disabled={
                                            values.referral_status === 'placed' && modifyOutcome
                                                ? false
                                                : version_changed ||
                                                  ['0', '1', '2'].includes(props.client.Program_Completion?.toString())
                                                ? true
                                                : values.referral_status !== 'placed'
                                        }
                                        name="Program_Completion"
                                        value={
                                            values.Program_Completion !== null
                                                ? values.Program_Completion && values.Program_Completion.toString()
                                                : ''
                                        }
                                    >
                                        <option value="">Select</option>
                                        <option value="1">Yes</option>
                                        <option value="0">No</option>
                                        <option value="2">Neutral Transfer​</option>
                                    </select>
                                    <ErrorMessage component="span" name="Program_Completion" />
                                </div>
                            </div>
                        )}
                        {values.referral_status === 'placed' &&
                            [0, 1, 2, '0', '1', '2'].includes(
                                values.Program_Completion && values.Program_Completion.toString()
                            ) && (
                                <div css={fieldRow}>
                                    <div css={twoCol}>
                                        <label css={label}>End Date</label>
                                    </div>
                                    <div css={twoCol}>
                                        <input
                                            type="date"
                                            name="end_date"
                                            disabled={
                                                modifyOutcome
                                                    ? false
                                                    : version_changed ||
                                                      ['0', '1', '2'].includes(
                                                          props.client.Program_Completion?.toString()
                                                      )
                                            }
                                            css={inputField}
                                            // disabled={Number(values.Program_Completion) === 0}
                                            placeholder=""
                                            min={values.start_date}
                                            value={values.end_date || ''}
                                            onChange={handleChange}
                                            onWheel={({ target }) => (target as HTMLInputElement).blur()}
                                        />
                                        <ErrorMessage component="span" name="end_date" />
                                    </div>
                                </div>
                            )}
                        {values.referral_status === 'placed' && (
                            <Fragment>
                                <div css={fieldRow}>
                                    <div css={twoCol}>
                                        <label css={label} htmlFor="program_significantly_modified">
                                            Was the program significantly modified to treat this client?
                                        </label>
                                    </div>
                                    <div css={twoCol}>
                                        <div css={fieldBox}>
                                            <input
                                                type="checkbox"
                                                disabled={
                                                    values.referral_status === 'placed' && modifyOutcome
                                                        ? false
                                                        : props.client.program_significantly_modified === 1 ||
                                                          (props.client.Remained_Out_of_Care &&
                                                              props.client.Remained_Out_of_Care.toString())
                                                        ? true
                                                        : version_changed
                                                        ? version_changed
                                                        : values.Program_Completion !== ''
                                                        ? false
                                                        : true
                                                }
                                                onChange={handleChange}
                                                name="program_significantly_modified"
                                                id="program_significantly_modified"
                                                value="true"
                                                checked={
                                                    values.program_significantly_modified !== null
                                                        ? Number(values.program_significantly_modified) === 1
                                                        : false
                                                }
                                            />
                                        </div>
                                        <ErrorMessage component="span" name="program_significantly_modified" />
                                    </div>
                                </div>

                                <div css={fieldRow}>
                                    <div css={twoCol}>
                                        <label css={label}>Remained Out of Care</label>
                                    </div>
                                    <div css={twoCol}>
                                        <select
                                            css={selectField}
                                            onChange={handleChange}
                                            disabled={
                                                values.referral_status === 'placed' && modifyOutcome
                                                    ? false
                                                    : props.client.Remained_Out_of_Care === 0 ||
                                                      props.client.Remained_Out_of_Care
                                                    ? true
                                                    : version_changed
                                                    ? version_changed
                                                    : values.Program_Completion !== ''
                                                    ? false
                                                    : true
                                            }
                                            name="Remained_Out_of_Care"
                                            value={
                                                values.Remained_Out_of_Care !== null
                                                    ? values.Remained_Out_of_Care &&
                                                      values.Remained_Out_of_Care.toString()
                                                    : ''
                                            }
                                        >
                                            <option value="">Select</option>
                                            <option value="1">Yes</option>
                                            <option value="0">No</option>
                                            {[0, '0'].includes(
                                                values.Program_Completion && values.Program_Completion.toString()
                                            ) && <option value="2">Step Up - Internal Transfer</option>}
                                            {[1, '1'].includes(
                                                values.Program_Completion && values.Program_Completion.toString()
                                            ) && <option value="3">Step Down - Internal Transfer</option>}
                                            {[0, 2, '0', '2'].includes(
                                                values.Program_Completion && values.Program_Completion.toString()
                                            ) && <option value="4">Lateral – Internal Transfer</option>}
                                            {[0, 1, '0', '1'].includes(
                                                values.Program_Completion && values.Program_Completion.toString()
                                            ) && <option value="5">Specialized – Internal Transfer</option>}
                                            <option value="6">Not Available</option>
                                        </select>
                                    </div>

                                    <ErrorMessage component="span" name="Remained_Out_of_Care" />
                                </div>
                            </Fragment>
                        )}
                        {modifyOutcome && (
                            <div css={fieldRow}>
                                <div css={textArea_50}>
                                    <label css={dangerLabel}>Reason for modification</label>
                                </div>
                                <div css={textArea_50}>
                                    <textarea
                                        css={textArea}
                                        id="comment"
                                        name="comment"
                                        value={comment || ''}
                                        onChange={(e) => setComment(e.target.value)}
                                        rows={6}
                                        placeholder=""
                                        required={true}
                                    ></textarea>
                                </div>
                            </div>
                        )}

                        <div css={fieldRow} style={{ justifyContent: 'flex-end' }}>
                            <Button
                                type="submit"
                                size="large"
                                variant="contained"
                                color="primary"
                                disabled={
                                    modifyOutcome
                                        ? false
                                        : version_changed ||
                                          ['not_placed', 'rejected', 'rescinded'].includes(
                                              props.client.referral_status
                                          ) ||
                                          props.client.Remained_Out_of_Care === 0 ||
                                          props.client.Remained_Out_of_Care
                                        ? true
                                        : false
                                }
                            >
                                Submit
                            </Button>
                        </div>
                        <Modal
                            isOpen={modalIsOpen}
                            ariaHideApp={false}
                            onRequestClose={closeModal}
                            style={customStyles}
                            contentLabel="Example Modal"
                        >
                            <h3>
                                Are you sure you want to {modifyOutcome ? 'modify outcome' : 'submit'}? <br />
                                {modifyOutcome
                                    ? ''
                                    : 'Submission will save this data into a version that cannot be changed'}
                            </h3>
                            <div css={fieldRow}>
                                <div css={twoCol}>&nbsp;</div>
                                <div css={twoCol}>
                                    <Button
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        disabled={version_changed}
                                        onClick={(e) => handleSubmit()}
                                    >
                                        Yes
                                    </Button>
                                </div>
                                <div css={twoCol}>
                                    <Button
                                        type="submit"
                                        size="large"
                                        variant="contained"
                                        color="primary"
                                        disabled={version_changed}
                                        onClick={(e) => setIsOpen(false)}
                                    >
                                        No
                                    </Button>
                                </div>
                                <div css={twoCol}></div>
                            </div>
                        </Modal>
                    </form>
                )}
            </Formik>

            {props.program_completion_response && <div css={subHeading}>{props.program_completion_response}</div>}
        </div>
    );
};

export default DynamicClientDetailsAdelphoi;
