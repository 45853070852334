/** @jsx jsx */
import React from 'react';
import { jsx } from '@emotion/core';
import Select from 'react-select';
import { components } from 'react-select';
import makeAnimated from 'react-select/animated';
import { Persues_House_Score } from '../components/TramaQuestion';
import '../App.css';

const animatedComponents = makeAnimated();

const Option = (props) => {
    
    return (
        <div style={{ display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
            <components.Option {...props}>
                <input type="checkbox" disabled={props.isDisabled} checked={props.isSelected} onChange={() => null} /> <label>{props.label}</label>
            </components.Option>
        </div>
    );
};

const colourStyles = {
    control: (provided: any, state: any) => ({
                ...provided,
                borderRadius: 0,
                borderColor: '#f5f5f5',
                borderBottom: '1px solid #8284e5',
                height: 45,
                backgroundColor: '#f5f5f5'
            }),
    option: (styles, { isSelected }) => {
        return {
            ...styles,
            backgroundColor: isSelected && 'transparent',
            color: 'black'
        };
    },
    menuPortal: (base) => ({
        ...base,
        zIndex: 9999 // Ensure the menu appears on top of other elements
    }),
    valueContainer: (base) => ({
        ...base,
        maxHeight: 38, // Set the fixed height for the value container
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    }),
    singleValue: (base) => ({
        ...base,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
    })
};

const Checkbox = ({ data, sectionIndex, questionIndex, state, handleChange, disableField, updateLocalState,  }) => {

    const dataState = Array.isArray(state)
        ? state.length > 0
            ? state[sectionIndex] && state[sectionIndex][data.question]
            : []
        : null;

    const getOptions = (selectedData, name) => {
        let options: any[] = [];
        
        selectedData.map((list, i) => {
            const enableAnswer = checkboxAnsDisable(
                state,
                data,
                list
            );
            let exactQuestion = list.value;
            let status = (state.isEdit === true && (list.is_deleted && data?.answer?.includes(list.value))) ? '( This option is no longer available. ) ' : ''
           
             if(!list.is_deleted || status !== '' ){
            options.push({
                value: list.id,
                label: (
                    <span
                        style={{
                            display: state.isEdit === true && list.is_deleted ? data?.answer?.includes(list.id) || data?.answer?.includes(list.value)? '' : 'none' : ''
                        }}
                        data-toggle="tooltip"
                        data-placement="top"
                        id={`${list.value} - ${name}`}
                    >
                        {exactQuestion} {status && <span style={{ color: '#f45050', fontWeight: 'bold' }}>{status}</span>}
                    </span>
                    ),
                id: list.id,
                isDeleted: list.is_deleted,
                name: name,
                title: list.value,
                hasAnswer: data?.answer?.toString(),
                model_suggested: list?.model_suggested ? list?.model_suggested : false,
                isChecked: list?.model_suggested ? list?.model_suggested : false,
                isDisabled: enableAnswer
            });
        }
        });

        return options;
    };

    const customFilterOption = (option, inputValue) => {
        return option.label?.props?.children[0]?.toLowerCase().includes(inputValue?.toLowerCase());
    };

    const checkboxAnsDisable = (client_form: any, ques: any, ans: any) => {
        
        if (client_form.hasOwnProperty('client_form')) {
            if(client_form.client_form[ques?.question] && client_form.client_form[ques?.question][0]){
            let findEqualSet =
                ans.setType ===
                ques.suggested_answers.find(
                    (answer) => client_form.client_form[ques?.question].includes(answer.id?.toString() || answer.id))
                ?.setType;
                
            return findEqualSet ? false : true;
                } else {
                    return false;
                }
        } else {
        if(client_form[sectionIndex] && client_form[sectionIndex][ques?.question] && client_form[sectionIndex][ques?.question][0]){
              
            let findEqualSet =
                    ans.setType ===
                    ques.suggested_answers.find(
                        (answer) => client_form[sectionIndex][ques?.question].includes( answer.id?.toString() || answer.id) || client_form[sectionIndex][ques?.question].includes( answer.value))
                    ?.setType;
                    
                return findEqualSet ? false : true;
                    } else {
                        return false;
                    }
        }
    };
    
    return (
        <React.Fragment>
            <Select
                key={questionIndex}
                value={getOptions(data.suggested_answers, data.question).filter(
                    (value) =>
                        (dataState ? dataState.includes(value.value?.toString()) || dataState.includes(value.title): data.answer?.includes(value.title) ||data.answer?.includes(value.id))
                )}
                styles={colourStyles}
                name={data.question}
                // components={animatedComponents}
                options={getOptions(data.suggested_answers, data.question)}
                className="my-custom-select"
                placeholder="Select"
                onChange={(selectedOption) => handleChange(selectedOption, data, sectionIndex, questionIndex, state, updateLocalState, Persues_House_Score)}
                isSearchable={true}
                filterOption={customFilterOption}
                id={data.question}
                isDisabled={disableField}
                isMulti
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                menuPortalTarget={document.body}
                components={{
                    IndicatorSeparator: () => null,
                    Option
                }}
            />
        </React.Fragment>
    );
};

export default Checkbox;
