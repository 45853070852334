import { ThunkAction } from 'redux-thunk';
import { AnyAction } from 'redux';
import createReducer from './createReducer';
import { QuestionsState } from './definitions/State';
import { AppState } from './root';
import * as Types from '../api/definitions';
import {
    insertDClient,
    insertPrediction,
    fetchLocations,
    saveLocationAndProgram,
    savePredictionsToCLient,
    saveProgramAndLikelihood,
    searchDClient,
    updateProgramCompletion,
    updateProgramCompletion1,
    fetchPcr,
    fetchPredictions,
    fetchProgramAndLikelihood,
    fetchProgramsForClient,
    fetchConfiguredQuestions,
    updateProgramCompletion2,
    dynamicSearchClient,
    getSearchFields,
    assignSelectedFamilies,
    getSoftSaveList,
    softSaveRecord,
    getSingleSavedRecord
} from '../api/api';
import { domainPath } from '../App';
//import { version, versions } from 'process';

const initialState: QuestionsState = {
    configuredQuestionsList: [],
    partiallySavedQuestions: [],
    client: Types.emptyClient,
    clientList: {},
    searchFieldsList: {},
    searchData: {},
    errors: {},
    uniqueIdField: ''
};
// TODO handle negative cases
// 1. new client creation fails - with and without excl.
// 2. submit prediction responds with error - no program found.
// 3. get locations responds with error - no locations found or empty array.

const { reducer, update } = createReducer<QuestionsState>('Client/UPDATE', initialState);
export const dynamicclientReducer = reducer;

export const actions = {
    update,
    getConfiguredQuestions(is_accessToken: any): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const response = await fetchConfiguredQuestions(is_accessToken, 1);
            let ConfiguredQuestions = response && response;
            let uniqueField = '';

            const primarySection = ConfiguredQuestions.find((sec) =>
                sec.questions.find((ques) => {
                    if (ques.is_primary_key) {
                        uniqueField = ques.question;
                        return true;
                    }
                    return false;
                })
            );

            dispatch(update({ configuredQuestionsList: ConfiguredQuestions, uniqueIdField: uniqueField , partiallySavedQuestions: []}));
            return response;
        };
    },

    updateProgramCompletion(
        client_code: string,
        Program_Completion: number | null,
        Returned_to_Care: number | null,
        Remained_Out_of_Care: number | null,
        program_significantly_modified: number,
        program: string | null,
        location: string | null,
        start_date: string | null,
        end_date: string | null,
        referral_status: string | null,
        confidence: number,
        roc_confidence: number,
        db_row_id: string | null,
        comment: string | null
        //is_accessToken: any | null
    ): ThunkAction<Promise<string>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const currentUser = getState().user?.user.accessToken;
            // if (program && location) {
            //   await saveLocationAndProgram(client_code, program, location, currentUser);
            // }
            const response = await updateProgramCompletion(
                client_code,
                Program_Completion,
                Returned_to_Care,
                Remained_Out_of_Care,
                program_significantly_modified,
                program,
                location,
                start_date,
                end_date,
                referral_status,
                confidence,
                roc_confidence,
                currentUser,
                db_row_id,
                comment
            );
            if (!response) {
                throw Error('something went wrong while submitting');
            }
            // return (response as unknown) as string;
            const clientState = getState().client;
            const clientList = clientState ? clientState.clientList : {};
            const searchData = clientState ? clientState.searchData : {};

            if (Object.keys(clientList).length > 0) {
                let cl: any = clientList[client_code];
                if (!cl) {
                    return response as unknown as string;
                }
                cl.referral_status = referral_status ? referral_status : null;
                cl.start_date = start_date ? start_date : null;
                cl.end_date = end_date ? end_date : null;
                cl.Program_Completion = Program_Completion ? Program_Completion : Program_Completion === 0 ? 0 : null;

                cl.Remained_Out_of_Care = Remained_Out_of_Care
                    ? Remained_Out_of_Care
                    : Remained_Out_of_Care === 0
                    ? 0
                    : null;

                cl.program_significantly_modified = program_significantly_modified
                    ? program_significantly_modified
                    : null;
                cl.selected_location = location ? location : null;
                cl.client_selected_program = program ? program : null;
                cl.client_selected_locations = location ? location : null;
                cl.client_selected_confidence = confidence;
                cl.client_selected_roc_confidence = roc_confidence;
                const updatedCl = {
                    ...cl,
                    Program_Completion,
                    Returned_to_Care,
                    Remained_Out_of_Care,
                    program_significantly_modified
                    // selected_location: location
                };
                if (!updatedCl.client_code) {
                    return response as unknown as string;
                }
                const updatedClList = {
                    ...clientList,
                    [client_code]: updatedCl
                };
                const updatedSearchData = [...searchData];
                updatedSearchData[searchData.length - 1] = updatedClList[client_code];

                dispatch(
                    update({
                        clientList: updatedClList,
                        searchData: updatedSearchData
                    })
                );
            }
            // dispatch(update({ client: clresult }));
            return response.status as unknown as string;
        };
    },

    updateProgramCompletion1(
        client_code: string,
        Program_Completion: number | null,
        Remained_Out_of_Care: number | null,
        program_significantly_modified: number,
        client_psychiatrically_hospitalized: number,
        program: string | null,
        discharge_location: string | null,
        start_date: string | null,
        end_date: string | null,
        referral_status: string | null,
        length_of_stay: number,
        Reason_not_accepted: string | null,
        Reason_for_rejected: string | null,
        client_recidivate: string | null,
        db_row_id: string | null,
        comment: string | null
    ): ThunkAction<Promise<string>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const currentUser = getState().user?.user.accessToken;
            const response = await updateProgramCompletion1(
                client_code,
                Program_Completion,
                Remained_Out_of_Care,
                program_significantly_modified,
                client_psychiatrically_hospitalized,
                program,
                discharge_location,
                start_date,
                end_date,
                referral_status,
                length_of_stay,
                Reason_not_accepted,
                Reason_for_rejected,
                client_recidivate,
                currentUser,
                db_row_id,
                comment
            );
            if (!response) {
                throw Error('something went wrong while submitting');
            }
            // return (response as unknown) as string;
            const clientState = getState().client;
            const clientList = clientState ? clientState.clientList : {};
            const searchData = clientState ? clientState.searchData : {};

            if (Object.keys(clientList).length > 0) {
                let cl: any = clientList[client_code];
                if (!cl) {
                    return response as unknown as string;
                }
                cl.referral_status = referral_status ? referral_status : null;
                cl.start_date = start_date ? start_date : null;
                cl.end_date = end_date ? end_date : null;
                cl.Program = program ? program : null;
                cl.discharge_location = discharge_location ? discharge_location : null;
                cl.length_of_stay = length_of_stay ? length_of_stay : null;
                cl.Reason_for_rejected = Reason_for_rejected?.toString() ? Reason_for_rejected?.toString() : null;
                cl.Reason_not_accepted = Reason_not_accepted?.toString() ? Reason_not_accepted?.toString() : null;
                cl.client_recidivate = client_recidivate ? client_recidivate : null;
                cl.Program_Completion = Program_Completion ? Program_Completion : Program_Completion === 0 ? 0 : null;
                cl.Remained_Out_of_Care = Remained_Out_of_Care
                    ? Remained_Out_of_Care
                    : Remained_Out_of_Care === 0
                    ? 0
                    : null;
                cl.program_significantly_modified = program_significantly_modified
                    ? program_significantly_modified
                    : null;
                cl.client_psychiatrically_hospitalized = client_psychiatrically_hospitalized?.toString()
                    ? client_psychiatrically_hospitalized?.toString()
                    : null;
                cl.selected_location = discharge_location ? discharge_location : null;
                const updatedCl = {
                    ...cl,
                    Program_Completion,
                    Remained_Out_of_Care,
                    program_significantly_modified
                    // selected_location: location
                };
                if (!updatedCl.client_code) {
                    return response as unknown as string;
                }

                const updatedClList = {
                    ...clientList,
                    [client_code]: updatedCl
                };
                const updatedSearchData = [...searchData];
                updatedSearchData[searchData.length - 1] = updatedClList[client_code];

                dispatch(
                    update({
                        clientList: updatedClList,
                        searchData: updatedSearchData
                    })
                );
            }
            // dispatch(update({ client: clresult }));
            return response as unknown as string;
        };
    },

    updateProgramCompletion2(
        clientForm: Types.DynamicClient,
        client_code: string
    ): ThunkAction<Promise<string>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const currentUser = getState().user?.user.accessToken;
            const response: any = await updateProgramCompletion2(clientForm, client_code, currentUser);
            if (response.status === 'failed') {
                throw Error(response.message);
            }
            // return (response as unknown) as string;
            const clientState = getState().client;
            const clientList = clientState ? clientState.clientList : {};
            const searchData = clientState ? clientState.searchData : {};
            const uniqueIdField = clientState ? clientState.uniqueIdField : {};

            const clientList1 =
                searchData.length > 0 && searchData.find((versions) => versions.version === clientForm['version']);
            const clientList2 = {
                [clientForm.client_code]: clientList1
            };

            if (Object.keys(clientList2).length > 0) {
                let cl: any = clientList2[clientForm.client_code];
                if (!cl) {
                    return response as unknown as string;
                }
                //Every time current version data
                const updatedCl = {
                    ...cl
                };

                let isValidClient: number | string = updatedCl[`${uniqueIdField}`];
                if (isValidClient === undefined) {
                    return response as unknown as string;
                }

                const updatedClList = {
                    ...clientList2,
                    [clientForm.client_code]: updatedCl
                };
                //Mapping Answer using clientForm
                const mapping_answer = (answer: any, answer_type: string, suggested_answers: any) => {
                    let data: any = [];
                    let dataAnswer: any = '';

                    if (Array.isArray(answer)) {
                        answer.filter((o1, i) => {
                            if (answer_type === 'CHECKBOX') {
                                suggested_answers.some((o2, j) => {
                                    if (o1 === o2?.id.toString()) {
                                        data.push(o2.value.toString());
                                    }
                                });
                            } else {
                                suggested_answers.some(
                                    (o2, j) => o1 === o2?.id.toString() && data.push(o2.value.toString())
                                );
                            }
                        });
                        return data;
                    } else {
                        if (['DATE', 'TEXT', 'NUMBER'].includes(answer_type)) {
                            dataAnswer = answer;
                        } else {
                            suggested_answers.some((o2) => {
                                if (answer === o2?.id.toString()) {
                                    dataAnswer = o2.value.toString();
                                }
                            });
                        }
                        return dataAnswer;
                    }
                };
                // Getting Section of current update + store answer
                let sections = [...updatedClList[clientForm.client_code]?.sections];
                let outcome = sections.map((section) => {
                    if (section.section === 'Outcomes') {
                        return {
                            ...section,
                            questions: section.questions.map((question, i) => {
                                return {
                                    ...question,
                                    answer: clientForm[question.question]
                                        ? mapping_answer(
                                              clientForm[question.question],
                                              question.answer_type,
                                              question.suggested_answers
                                          )
                                        : ''
                                };
                            })
                        };
                    }
                    return section;
                });

                let outComeSection = {
                    [clientForm.client_code]: {
                        ...updatedClList[clientForm.client_code],
                        sections: outcome
                    }
                };
                //Find & Update : Find current version, Update the current version data into specific version (searchData)
                const SearchDataAll = [...searchData];
                const updatedSearchData = SearchDataAll.map((versions) => {
                    if (versions.version === clientForm['version']) {
                        return {
                            ...versions,
                            sections: outComeSection[clientForm.client_code]?.sections
                        };
                    }
                    return versions;
                });

                dispatch(
                    update({
                        clientList: outComeSection,
                        searchData: updatedSearchData
                    })
                );
            }

            return response.status as unknown as string;
        };
    },

    getLocations(
        client_code: string,
        selected_program: string,
        is_accessToken: any
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const response = await fetchLocations(client_code, selected_program, is_accessToken);
            const locations = response ? response['Suggested Locations'] : [];
            if (locations.length > 0) {
                const cl: Types.Client = {
                    ...getState().client!.client,
                    SuggestedLocations: [...locations],
                    client_selected_program: selected_program
                };
                dispatch(update({ client: cl }));
                const clientList = getState().client?.clientList;
                if (clientList && clientList[Number(client_code)]) {
                    const client = clientList[client_code];
                    const cl: Types.Client = {
                        ...client,
                        SuggestedLocations: [...locations],
                        Program_Completion: 0,
                        selected_program,
                        selected_location: null
                    };
                    clientList[client_code] = cl;
                    dispatch(update({ clientList }));
                }
            }
        };
    },

    getPcr(client_code: string, selected_program: string): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const currentUser = getState().user?.user.accessToken;
            const response = await fetchPcr(client_code, selected_program, currentUser);
            const pcr: number | null = response ? response.pcr : null;
            const roc_confidence: number | null = response ? response.roc_confidence : null;
            if (pcr !== null) {
                const cl: Types.Client = {
                    ...getState().client!.client,
                    Confidence: pcr,
                    confidence: pcr,
                    Roc_confidence: roc_confidence,
                    roc_confidence: roc_confidence,
                    selected_program,
                    client_selected_program: selected_program
                };
                dispatch(update({ client: cl }));
                const clientList = getState().client?.clientList;
                if (clientList && clientList[Number(client_code)]) {
                    const client = clientList[client_code];
                    const cl: Types.Client = {
                        ...client,
                        Confidence: pcr,
                        confidence: pcr,
                        Roc_confidence: roc_confidence,
                        roc_confidence: roc_confidence,
                        Program_Completion: 0,
                        selected_program,
                        client_selected_confidence: pcr,
                        client_selected_roc_confidence: roc_confidence,
                        client_selected_program: selected_program
                    };

                    clientList[client_code] = cl;
                    dispatch(update({ clientList }));
                }
            }
        };
    },

    getPredictions(
        client_code: string,
        selected_program: string,
        selected_name: string,
        is_prediction_screen: boolean,
        isPredictionScores: boolean
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const currentUser = getState().user?.user.accessToken;
            const QuestionsState = getState().dynamicclient;
            let clientList = getState().client?.clientList;
            const configuredQuestionsList = QuestionsState ? QuestionsState.configuredQuestionsList : [];
            const response = await fetchPredictions(
                client_code,
                selected_program,
                currentUser,
                selected_name,
                is_prediction_screen,
                isPredictionScores
            );
            if (response.status === 'failed') {
                return response;
            }
            if (response?.response?.length > 0 && is_prediction_screen) {
                let OutcomesSection = configuredQuestionsList.filter((sec) => sec.section === 'Outcomes');

                let WithoutOutcomes = configuredQuestionsList.filter((sec) => sec.section !== 'Outcomes');

                OutcomesSection = OutcomesSection.map((sec) => {
                    return {
                        ...sec,
                        questions: sec.questions.filter((ques) => ques.for_prediction_screen !== 'yes')
                    };
                });
                OutcomesSection[0].questions = [...OutcomesSection[0].questions, ...response.response].sort(
                    (a, b) => a.id - b.id
                );

                let DynQuestions = [...WithoutOutcomes, ...OutcomesSection];

                dispatch(update({ configuredQuestionsList: DynQuestions }));
            }

            if (
                clientList &&
                clientList[Number(client_code)] &&
                !is_prediction_screen &&
                response?.response.length > 0
            ) {
                let client = clientList[client_code];
                let OutcomesSection = client.sections.filter((sec) => sec.section === 'Outcomes');

                let WithoutOutcomes = client.sections.filter((sec) => sec.section !== 'Outcomes');

                OutcomesSection = OutcomesSection.map((sec) => {
                    return {
                        ...sec,
                        questions: sec.questions.filter((ques) => ques.for_prediction_screen !== 'yes')
                    };
                });
                OutcomesSection[0].questions = [...OutcomesSection[0].questions, ...response.response].sort(
                    (a, b) => a.id - b.id
                );

                client.sections = [...WithoutOutcomes, ...OutcomesSection];

                const cl: Types.Client = {
                    ...client
                };

                clientList[client_code] = cl;
                dispatch(update({ clientList }));
            }
            return response.response;
        };
    },

    getProgramAndLikelihood(
        client_code: string,
        selected_program: string
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const currentUser = getState().user?.user.accessToken;
            const response = await fetchProgramAndLikelihood(client_code, selected_program, currentUser);
            const pcr: number | null = response ? response['Program/Pathway Successful Completion Likelihood'] : null;
            const roc_confidence: number | null = response
                ? response['Remain Out of Residential Placement Likelihood']
                : null;
            const Likelihood_to_Recidivate1: number | null = response
                ? response['Likelihood to Recidivate: 1 Year']
                : null;
            const Likelihood_to_Recidivate2: number | null = response
                ? response['Likelihood to Recidivate: 2 Years']
                : null;
            if (pcr !== null) {
                const cl: Types.Client = {
                    ...getState().client!.client,
                    Confidence: pcr,
                    confidence: pcr,
                    Roc_confidence: roc_confidence,
                    roc_confidence: roc_confidence,
                    Likelihood_to_Recidivate1: Likelihood_to_Recidivate1,
                    Likelihood_to_Recidivate2: Likelihood_to_Recidivate2,
                    // selected_program,
                    client_selected_program: selected_program
                };
                dispatch(update({ client: cl }));
                const clientList = getState().client?.clientList;

                if (clientList && clientList[client_code]) {
                    let client = clientList[client_code];

                    const cl: Types.Client = {
                        ...client,
                        Confidence: pcr,
                        confidence: pcr,
                        Roc_confidence: roc_confidence,
                        roc_confidence: roc_confidence,
                        Program_Completion: 0,
                        //selected_program,
                        client_selected_confidence: pcr,
                        client_selected_roc_confidence: roc_confidence,
                        Likelihood_to_Recidivate1: Likelihood_to_Recidivate1,
                        Likelihood_to_Recidivate2: Likelihood_to_Recidivate1,
                        client_selected_program: selected_program
                    };

                    clientList[client_code] = cl;
                    dispatch(update({ clientList }));
                    return response;
                }
            }
        };
    },

    updateFormValues(client_code: string, values: any): ThunkAction<void, AppState, null, AnyAction> {
        return (dispatch, getState) => {
            const clientList = getState().client?.clientList;
            if (clientList && clientList[Number(client_code)]) {
                const client = clientList[client_code];
                const cl: Types.Client = {
                    ...client,
                    Program_Completion: values.Program_Completion,
                    Returned_to_Care: values.Returned_to_Care,
                    Remained_Out_of_Care: values.Remained_Out_of_Care,
                    program_significantly_modified: values.program_significantly_modified
                };
                clientList[client_code] = cl;
                dispatch(update({ clientList }));
            }
        };
    },

    getProgramsForClient(client_code: string, version: string): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const currentUser = getState().user?.user.accessToken;
            const is_prediction_available =
                domainPath === 'demo' ? true : getState().user?.user.is_prediction_available;
            const clientList = getState().client?.clientList;
            const sDataList = getState().client?.searchData;

            if (!clientList || !clientList[Number(client_code)]) {
                throw new Error('client not found');
            }

            const sData = sDataList.filter((a) => a.client_code || Number(a.id_n) === Number(client_code));
            const client = clientList[client_code];
            const latestVersion = version ? version : sData.length - 1;

            if (!is_prediction_available) {
                clientList[client_code] = sData[latestVersion];
                dispatch(update({ clientList }));
            } else {
                const response = await fetchProgramsForClient(client_code, currentUser);
                if (response) {
                    const cl: Types.Client = {
                        ...client,
                        SuggestedPrograms: version
                            ? sData[latestVersion].client_selected_program !== undefined
                                ? [sData[latestVersion].client_selected_program]
                                : client.SuggestedPrograms
                            : response.program_model_suggested
                            ? response.program_model_suggested
                            : client.SuggestedPrograms || null,
                        SuggestedLocations: sData[latestVersion].client_selected_locations
                            ? [sData[latestVersion].client_selected_locations]
                            : [],
                        selected_program: sData[latestVersion].client_selected_program
                            ? sData[latestVersion].client_selected_program
                            : sData[latestVersion].Program,
                        Program: sData[latestVersion].Program,
                        referral_status: sData[latestVersion].referral_status
                            ? sData[latestVersion].referral_status
                            : null,
                        start_date: sData[latestVersion].referral_status ? sData[latestVersion].start_date : null,
                        end_date: sData[latestVersion].referral_status ? sData[latestVersion].end_date : null,
                        Reason_for_rejected: sData[latestVersion].Reason_for_rejected?.toString()
                            ? sData[latestVersion].Reason_for_rejected?.toString()
                            : null,
                        Reason_not_accepted: sData[latestVersion].Reason_not_accepted?.toString()
                            ? sData[latestVersion].Reason_not_accepted?.toString()
                            : null,
                        selected_location: sData[latestVersion].client_selected_locations,
                        Program_Completion: sData[latestVersion].Program_Completion,
                        Returned_to_Care: sData[latestVersion].Returned_to_Care,
                        Remained_Out_of_Care:
                            sData[latestVersion].Remained_Out_of_Care === null
                                ? null
                                : sData[latestVersion].Remained_Out_of_Care?.toString(),
                        ageAtEpisodeStart: sData[latestVersion].ageAtEpisodeStart,
                        client_selected_locations: sData[latestVersion].client_selected_locations,
                        client_selected_program: sData[latestVersion].client_selected_program,
                        confidence: sData[latestVersion].confidence,
                        model_program: sData[latestVersion].model_program,
                        program_significantly_modified: sData[latestVersion].program_significantly_modified,
                        client_psychiatrically_hospitalized: sData[latestVersion].client_psychiatrically_hospitalized,
                        referred_program: sData[latestVersion].referred_program,
                        roc_confidence: sData[latestVersion].roc_confidence,
                        length_of_stay: sData[latestVersion].length_of_stay?.toString(),
                        discharge_location: sData[latestVersion].discharge_location?.toString(),
                        client_recidivate: sData[latestVersion].client_recidivate?.toString(),
                        client_selected_confidence: sData[latestVersion].client_selected_confidence,
                        client_selected_roc_confidence: sData[latestVersion].client_selected_roc_confidence
                    };
                    clientList[client_code] = cl;
                    dispatch(update({ clientList }));
                }
            }
        };
    },

    saveLocationAndProgram(
        selected_location: string,
        pcr_score: any,
        roc_score: any,
        isPredictionScores?: boolean,
        selected_program?: string
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const client = getState().client!.client;
            const QuestionsState = getState().dynamicclient;
            const clientState = getState().client;
            const clientList = clientState ? clientState.clientList : {};
            const searchData = clientState ? clientState.searchData : [];
            const uniqueField = QuestionsState ? QuestionsState.uniqueIdField : [];
            const currentUser = getState().user?.user.accessToken;
            const cl: Types.Client = {
                ...client,
                client_selected_locations: selected_location
            };
            dispatch(update({ client: cl }));
            let programParam: string;
            if (!selected_program && client.client_selected_program && client.client_selected_program[0]) {
                programParam = Array.isArray(client.client_selected_program)
                    ? client.client_selected_program[0]
                    : client.client_selected_program;
            } else if (selected_program) {
                programParam = selected_program;
            } else {
                throw Error('something went wrong while submitting');
            }
            let client_code =
                client && client.hasOwnProperty('New Client Code') && client['New Client Code'] !== ''
                    ? client['New Client Code']
                    : client && client[`${uniqueField}`];
            const response: any = await saveLocationAndProgram(
                client_code!,
                programParam,
                selected_location,
                pcr_score,
                roc_score,
                currentUser,
                isPredictionScores
            );

            if (!response) {
                throw Error('something went wrong while submitting');
            }

            const clresult: Types.Client = {
                ...cl,
                result_final: response.result
            };

            dispatch(update({ client: clresult }));

            if (!isPredictionScores) {
                const updatedCL = {};
                updatedCL[client[`${uniqueField}`]] = {
                    ...Object.values(clientList)[0],
                    client_selected_program: programParam,
                    client_selected_locations: selected_location,
                    client_selected_confidence: pcr_score,
                    client_selected_roc_confidence: roc_score
                };

                const updatedSData = [...searchData];
                updatedSData[searchData.length - 1] = {
                    ...updatedSData[searchData.length - 1],
                    client_selected_program: programParam,
                    client_selected_locations: selected_location,
                    client_selected_confidence: pcr_score,
                    client_selected_roc_confidence: roc_score
                };
                dispatch(update({ clientList: updatedCL, searchData: updatedSData }));
            }
            // return clresult;
        };
    },

    savePredictions(
        client_code: string,
        data: any,
        is_accessToken: string,
        isPredictionScores?: boolean
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            try {
                const response: any = await savePredictionsToCLient(
                    client_code!,
                    data,
                    is_accessToken,
                    isPredictionScores
                );
                return response;
            } catch (error) {
                throw error;
            }
        };
    },

    saveProgramAndLikelihood(
        selected_location: string,
        pcr_score: any,
        roc_score: any,
        isPredictionScores?: boolean,
        selected_program?: string
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const client = getState().client!.client;
            const clientState = getState().client;
            const clientList = clientState ? clientState.clientList : {};
            const searchData = clientState ? clientState.searchData : [];
            const uniqueField = clientState ? clientState.uniqueIdField : [];
            const currentUser = getState().user?.user.accessToken;
            const cl: Types.Client = {
                ...client,
                client_selected_locations: selected_location
            };
            dispatch(update({ client: cl }));
            let programParam: string;
            if (!selected_program && client.client_selected_program?.toString()) {
                programParam = Array.isArray(client.client_selected_program)
                    ? client.client_selected_program[0]
                    : client.client_selected_program;
            } else if (selected_program) {
                programParam = selected_program;
            } else {
                throw Error('something went wrong while submitting');
            }

            let client_code = client && client[`${uniqueField}`];
            const response: any = await saveProgramAndLikelihood(
                client_code!,
                programParam,
                selected_location,
                pcr_score,
                roc_score,
                currentUser,
                isPredictionScores
            );

            if (!response) {
                throw Error('something went wrong while submitting');
            }

            const clresult: Types.Client = {
                ...cl,
                result_final: response.result
            };

            dispatch(update({ client: clresult }));

            if (!isPredictionScores) {
                const updatedCL = {};
                updatedCL[client[`${uniqueField}`]] = {
                    ...Object.values(clientList)[0],
                    client_selected_program: programParam,
                    client_selected_locations: selected_location,
                    client_selected_confidence: pcr_score,
                    client_selected_roc_confidence: roc_score
                };

                const updatedSData = [...searchData];
                updatedSData[searchData.length - 1] = {
                    ...updatedSData[searchData.length - 1],
                    client_selected_program: programParam,
                    client_selected_locations: selected_location,
                    client_selected_confidence: pcr_score,
                    client_selected_roc_confidence: roc_score
                };
                dispatch(update({ clientList: updatedCL, searchData: updatedSData }));
            }
            return response;
        };
    },

    saveSelectedFamiliesToYouth(
        version: string,
        data: any,
        is_accessToken: string
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const client = getState().client!.client;
            const clientState = getState().client;
            const uniqueField = clientState ? clientState.uniqueIdField : '';
            let finalVersion = client?.MappedFamiliesToYouth?.version ? client?.MappedFamiliesToYouth?.version : 'v1';
            
           let finalData = data.map((item,index) => {
            return(
            {
                _id: item._id,
                [uniqueField]: item.id_n,
                value: item.value
            }
           )})

            const response: any = await assignSelectedFamilies(
                client[`${uniqueField}`],
                finalVersion,
                finalData,
                is_accessToken,
                uniqueField
            );

            if (!response) {
                throw Error('something went wrong while submitting');
            }
            return response;
        };
    },

    submitPrediction(client: Types.Client): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            if (!client.client_code) {
                throw new Error('client code required');
            }
            dispatch(update({ client }));
            try {
                await insertPrediction(client);
            } catch (error) {
                throw error;
            }
        };
    },

    searchDClient(
        client_code: string,
        ssn: string,
        first_name: string,
        last_name: string,
        dob: string,
        is_accessToken: any,
        version: number,
        id_n: string
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const response = await searchDClient(client_code, ssn, first_name, last_name, dob, is_accessToken, id_n);
            let arr = response.response;

            let clientList: { [key: string]: any } = {};
            arr &&
                arr.map((c: any, i: number) => {
                    if (c.client_code) {
                        if (c.hasOwnProperty('Remained Out of Care')) {
                            c.Remained_Out_of_Care = c['Remained Out of Care'];
                            delete c['Remained Out of Care'];
                        }
                        return (clientList[c.client_code] = c);
                    }
                    if (c.id_n) {
                        if (version === i) {
                            return (clientList[c.id_n] = c);
                        }
                    }
                    if (c.id_n) {
                        if (version === undefined) {
                            return (clientList[c.id_n] = c);
                        }
                    }
                    return null;
                });
            dispatch(update({ clientList, searchData: response.response }));
            let locations = arr ? (arr[0] && arr[0]['Suggested Locations'] ? arr[0]['Suggested Locations'] : []) : [];
            let model_program = arr ? (arr[0] && arr[0].model_program ? arr[0].model_program : []) : [];

            if (model_program.length === 0) {
                let program =
                    domainPath === 'demo'
                        ? ['Program One']
                        : [
                              'Andromeda House RTF',
                              'Andromeda House ITU',
                              'Brighter Horizons',
                              'Girls Enhanced RTF',
                              'Boys Enhanced RTF',
                              'Perseus House',
                              '7th Street'
                          ];
                const cl: Types.Client = {
                    ...getState().client!.client,
                    SuggestedPrograms: program
                };
                dispatch(update({ client: cl }));
                const clientList = getState().client?.clientList;
                if (clientList && clientList[Number(client_code)]) {
                    const client = clientList[client_code];
                    const cl: Types.Client = {
                        ...client,
                        SuggestedPrograms: program
                    };
                    clientList[client_code] = cl;
                    dispatch(update({ clientList }));
                }
            }

            if (locations.length > 0) {
                const cl: Types.Client = {
                    ...getState().client!.client,
                    SuggestedLocations: [...locations]
                };
                dispatch(update({ client: cl }));
                const clientList = getState().client?.clientList;
                if (clientList && clientList[Number(client_code)]) {
                    const client = clientList[client_code];
                    const cl: Types.Client = {
                        ...client,
                        SuggestedLocations: [...locations]
                    };
                    clientList[client_code] = cl;
                    dispatch(update({ clientList }));
                }
            }

            return;
        };
    },

    dynamicSearchClient(
        data: string[],
        is_accessToken: string,
        version: number,
        keySearch: any
    ): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            const response = await dynamicSearchClient(data, is_accessToken);
            let resData = response;
            
            let clientList: { [key: string]: any } = {};
            resData &&
                resData.map((c: any, i: number) => {
                    c.sections.map((sec) =>
                        sec.questions.map((q: any, index: number) => {
                            if (q.question?.toLowerCase() === keySearch?.toLowerCase()) {
                                if (c.hasOwnProperty('Remained Out of Care')) {
                                    c.Remained_Out_of_Care = c['Remained Out of Care'];
                                    delete c['Remained Out of Care'];
                                }
                                return (clientList[q.answer] = c);
                            }
                            if (c.id_n) {
                                if (version === i) {
                                    return (clientList[c.id_n] = c);
                                }
                            }
                            if (c.id_n) {
                                if (version === undefined) {
                                    return (clientList[c.id_n] = c);
                                }
                            }
                        })
                    );
                    return null;
                });
           
            dispatch(update({ clientList, searchData: response }));
            let locations = resData
                ? resData[0] && resData[0]['Suggested Locations']
                    ? resData[0]['Suggested Locations']
                    : []
                : [];
            let model_program = resData ? (resData[0] && resData[0].model_program ? resData[0].model_program : []) : [];

            if (model_program.length === 0) {
                let program =
                    domainPath === 'demo'
                        ? ['Program One']
                        : [
                              'Andromeda House RTF',
                              'Andromeda House ITU',
                              'Brighter Horizons',
                              'Girls Enhanced RTF',
                              'Boys Enhanced RTF',
                              'Perseus House',
                              '7th Street'
                          ];
                const cl: Types.Client = {
                    ...getState().client!.client,
                    SuggestedPrograms: program
                };
                dispatch(update({ client: cl }));
                const clientList = getState().client?.clientList;
                if (clientList && clientList[`${keySearch}`]) {
                    const client = clientList[`${keySearch}`];
                    const cl: Types.Client = {
                        ...client,
                        SuggestedPrograms: program
                    };
                    clientList[`${keySearch}`] = cl;
                    dispatch(update({ clientList }));
                }
            }

            if (locations.length > 0) {
                const cl: Types.Client = {
                    ...getState().client!.client,
                    SuggestedLocations: [...locations]
                };
                dispatch(update({ client: cl }));
                const clientList = getState().client?.clientList;
                if (clientList && clientList[`${keySearch}`]) {
                    const client = clientList[`${keySearch}`];
                    const cl: Types.Client = {
                        ...client,
                        SuggestedLocations: [...locations]
                    };
                    clientList[`${keySearch}`] = cl;
                    dispatch(update({ clientList }));
                }
            }

            return;
        };
    },

    getSearchFields(is_accessToken: string): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            try {
                const response = await getSearchFields(is_accessToken, '1');
                let searchFieldsList: { [key: string]: Types.DynamicFamily } = {};
                let data = response.response;
                searchFieldsList = data;

                dispatch(update({ searchFieldsList }));
                return data;
            } catch (errors) {
                // const client :any ={ response: "failed"}
                dispatch(update({ errors: errors }));
                return errors;
            }
        };
    },

    insertDClient(
        Dclient: Types.DynamicClient,
        action: string,
        is_accessToken: any,
        isPredictionScores?: boolean
    ): ThunkAction<Promise<any>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            let locations: string[] = [];
            let updatedDClient: Types.DynamicClient;
            const is_prediction_available = getState().user?.user.is_prediction_available;
            const QuestionsState = getState().dynamicclient;
            const configuredQuestionsList = QuestionsState ? QuestionsState.configuredQuestionsList : [];

            try {
                const response = await insertDClient(Dclient, action, is_accessToken, isPredictionScores);

                if (response.data.response.length > 0) {
                    let OutcomesSection = configuredQuestionsList.filter((sec) => sec.section === 'Outcomes');

                    let WithoutOutcomes = configuredQuestionsList.filter((sec) => sec.section !== 'Outcomes');

                    OutcomesSection = OutcomesSection.map((sec) => {
                        return {
                            ...sec,
                            questions: sec.questions.filter((ques) => ques.for_prediction_screen !== 'yes')
                        };
                    });
                    OutcomesSection[0].questions = [...OutcomesSection[0].questions, ...response.data.response].sort(
                        (a, b) => a.id - b.id
                    );

                    let DynQuestions = [...WithoutOutcomes, ...OutcomesSection];
                    dispatch(update({ configuredQuestionsList: DynQuestions }));
                }
                const cl = {
                    ...Dclient,
                    program_type: response.data.response?.hasOwnProperty('Program')
                        ? (response.data.response['Program'] && response.data.response['Program']) || null
                        : (response.data.response.program_type && response.data.response.program_type) || null,
                    referral_type:
                        (response.data.response['Referral Type'] && response.data.response['Referral Type']) || null,
                    Confidence: (response.data.response.confidence && response.data.response.confidence) || null,
                    Roc_confidence: response.data.response?.hasOwnProperty(
                        'Remain Out of Residential Placement Likelihood'
                    )
                        ? (response.data.response['Remain Out of Residential Placement Likelihood'] &&
                              response.data.response['Remain Out of Residential Placement Likelihood']) ||
                          null
                        : (response.data.response.roc_confidence && response.data.response.roc_confidence) || null,
                    referred_program:
                        (response.data.response.program_type && response.data.response.program_type) || null,
                    model_program:
                        (response.data.response.model_program && response.data.response.model_program) || null,
                    SuggestedPrograms: response.data.response?.hasOwnProperty('Programs List')
                        ? (response.data.response['Programs List'] && response.data.response['Programs List']) || null
                        : (response.data.response.list_program_types && response.data.response.list_program_types) ||
                          null,
                    SuggestedFamilies: (response.data.response.families && response.data.response.families) || null,
                    MappedFamiliesToYouth:
                        (response.data.response.youth_info && response.data.response.youth_info) || null,
                    super_vision_list:
                        (response.data.response['Supervision Level'] && response.data.response['Supervision Level']) ||
                        null
                };

                dispatch(update({ client: cl, partiallySavedQuestions: [] }));
                if (is_prediction_available && response.data.response.length === 0) {
                    if (response.data.status !== 'failed' && !response.data.response?.hasOwnProperty('Programs List')) {
                        if (Dclient['Exclusionary Criteria Exists/Referral Rejected'][0] !== '0') {
                            updatedDClient = cl;
                            const res = await fetchLocations(
                                Object.keys(updatedDClient).includes('New Client Code')
                                    ? updatedDClient['New Client Code']
                                    : updatedDClient['Client Code'],
                                updatedDClient.program_type,
                                is_accessToken
                            );
                            if (res && res['result'] && res['result'] !== '') {
                                throw new Error(res['result']);
                            }
                            if (res && res['Suggested Locations']) {
                                locations = res['Suggested Locations'];
                            }
                            if (locations.length > 0) {
                                const cl: Types.DynamicClient = {
                                    ...updatedDClient,
                                    SuggestedLocations: [...locations],
                                    client_selected_program: updatedDClient.program_type[0]
                                };
                                dispatch(update({ client: cl }));
                            }
                        }
                    }
                }

                // return cl;
                return response;
            } catch (errors) {
                // const client :any ={ response: "failed"}
                dispatch(update({ errors: errors }));
                return errors;
            }
        };
    },

    partialSavedList(
        configType: string,
        is_accessToken: any
    ): ThunkAction<Promise<any>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            try {
                const response = await getSoftSaveList(configType, is_accessToken);
                dispatch(update({partiallySavedQuestions: []}))
                 return response;
            } catch (errors) {
                // const client :any ={ response: "failed"}
                dispatch(update({ errors: errors }));
                return errors;
            }
        };
    },

    softSaveClient(
        Dclient: Types.DynamicClient,
        is_accessToken: any
    ): ThunkAction<Promise<any>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            try {
                const response = await softSaveRecord(Dclient, 1, is_accessToken);
                dispatch(update({partiallySavedQuestions: []}))
                 return response;
            } catch (errors) {
                // const client :any ={ response: "failed"}
                dispatch(update({ errors: errors }));
                return errors;
            }
        };
    },

    getSingleSavedClient(
        id: string,
        is_accessToken: string,
        methodType: string
       
    ): ThunkAction<Promise<any>, AppState, null, AnyAction> {
        return async (dispatch, getState) => {
            try {
                const response = await getSingleSavedRecord( 1, is_accessToken, id, methodType );
                let result ;
                if(response && response.response && Array.isArray(response.response)) {
                    dispatch(update({partiallySavedQuestions: response?.response[0]}))
                    result = response.response[0]
                } else if(methodType === 'delete' && response && response.response === "" ) {
                    result = []
                } else {
                    result = null;
                }
               
                 return result;
            } catch (errors) {
                // const client :any ={ response: "failed"}
                dispatch(update({ errors: errors }));
                return errors;
            }
        };
    },

    clear(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch) => {
            dispatch(
                update({
                    client: Types.emptyDClient
                    // page1FormCompleted: false,
                    // excludePage2: false
                })
            );
        };
    },

    clearErrors(): ThunkAction<Promise<void>, AppState, null, AnyAction> {
        return async (dispatch) => {
            dispatch(update({ errors: {} }));
        };
    }
};
