/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { domainPath } from '../../App';
import { modifyOutcomeButton } from '../styles';
const ModifyHardSaveData = ({ client, is_role_type, SelectedVersion, modifyOutcome, setModifyOutcome, orgType, latestVersion }) => {
    const [exclusionary_criteria, setExclusionary_Criteria] = useState('No');
    const [enableOutcomes, setEnableOutcomes] = useState<boolean>(false);

    useEffect(() => {
        if (SelectedVersion) {
            let isExclusionary =
                SelectedVersion &&
                SelectedVersion.sections
                    .map(
                        (section) =>
                            section &&
                            section.questions.some(
                                (ques) => ques.question === 'Exclusionary Criteria Exists/Referral Rejected'
                            )
                    )
                    ?.find((item) => item !== false);
            if (isExclusionary) {
                SelectedVersion.sections.map((section) => {
                    section &&
                        section.questions.find((question) => {
                            if (question.question === 'Exclusionary Criteria Exists/Referral Rejected') {
                                setExclusionary_Criteria(
                                    Array.isArray(question.answer) ? question.answer[0] : question.answer
                                );
                            }
                        });
                });
            } else {
                if([3,4].includes(orgType)) {
                setExclusionary_Criteria('No');
                } else {
                    setExclusionary_Criteria('Yes');
                }
            }
        }

        
        if(latestVersion?.version === SelectedVersion?.version) {
            setEnableOutcomes(latestVersion?.version === SelectedVersion?.version);
            

        } else {
            setModifyOutcome('');
            setEnableOutcomes(latestVersion?.version === SelectedVersion?.version);
        }

    }, [SelectedVersion]);
    
    
   
    return (
        <Fragment>
            {['Super Admin', 'Tester'].includes(is_role_type) ? (
                <Fragment>
                    Modify
                    <Link
                        to={{
                            pathname: `/${domainPath}/existing-client/edit-details/${client}&true&false`,
                            state: {
                                isModifyVersion: SelectedVersion && SelectedVersion._id,
                                isPredictionScores: false
                            }
                        }}
                    >
                        <Button>
                            <u css={modifyOutcomeButton}>
                                <strong>Client</strong>
                            </u>
                        </Button>
                    </Link> 
                    {exclusionary_criteria === 'Yes' ? (
                        ''

                    ) : (enableOutcomes) &&  (


                        <Fragment>
                            &nbsp;|
                            <Button
                                onClick={() => setModifyOutcome(modifyOutcome ? '' : SelectedVersion._id)}
                            >
                                <u css={modifyOutcomeButton}>
                                    <strong style={{ color: modifyOutcome ? 'blue' : 'red' }}>Outcome</strong>
                                </u>
                            </Button>
                        </Fragment>
                    )}
                </Fragment>
            ) : (
                ''
            )}
        </Fragment>
    );
};

export default ModifyHardSaveData;
