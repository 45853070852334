import React from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Button } from '@material-ui/core';

interface ConfirmationModalProps {
  open: boolean;
  onClose: (confirmed: boolean) => void;
}

const ConfirmationModal: React.FC<ConfirmationModalProps> = ({ open, onClose }) => {
  return (
    <Dialog
      open={open}
      onClose={() => onClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">Update Available</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          A new version is available. Would you like to update?
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(false)} color="primary">
          No
        </Button>
        <Button onClick={() => onClose(true)} color="primary" autoFocus>
          Yes
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmationModal;
