import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from 'react-router-dom';
import { withSnackbar, WithSnackbarProps } from 'notistack';
import * as Types from '../api/definitions';
import { AppState } from '../redux-modules/root';
import { ContainerProps } from './Container';
import * as client from '../redux-modules/client';
import * as family from '../redux-modules/family';
import * as dynamicclient from '../redux-modules/dynamicclient';
import PredictionFormStep from '../components/PredictionFormStep';
import PredictionFormStep2 from '../components/PredictionFormStep2';
import BasicRequirementStep1 from '../components/BasicRequirementStep1';
import ProgramSelection from '../components/ProgramSelection';
import DynamicProgramSelection from '../components/DynamicProgramSelection';
import CountyProgramSelection from '../components/CountyProgramSelection';
import { domainPath } from '../App';
import { dynamicSearchFamily, getSoftSaveList } from '../api/api';
interface MatchParams {
    index: string;
    Rerefer: string;
    isEdit: string;
}
export interface DynamicNewClientContainerState {
    isLoading: boolean;
    error: any;
    hasError: boolean;
    isSuccess: boolean;
    isPredictionScores: boolean;
    isModifyVersion: string;
    refferalInfo: string | any;
    predictionResult: string[] | any;
    uniqueIdField: string;
    isSavedClientLoaded: boolean;
    softSavedRecord: string[];
    tempDataId: string;
    progressMessage: string;
    isNoExcluCriteria: boolean;
    successMessage: string;
}
export interface PredictionScore {
    isPredictionScores: boolean;
}
export interface ModifyVersion {
    isModifyVersion: string;
}

export interface referral_info {
    referral_info: string;
}

export interface DynamicClient {
    [key: string]: any;
}

type CallbackType = (result: boolean) => void;

export interface DynamicNewClientContainerProp extends ContainerProps<MatchParams>, WithSnackbarProps {
    saveClient: (client: Types.Client, page1FormCompleted?: boolean, excludePage2?: boolean) => void;
    insertDClient: (
        client: Types.DynamicClient,
        action: string,
        is_accessToken: any,
        isPredictionScores: boolean
    ) => Promise<void>;
    softSaveClient: (
        client: Types.DynamicClient,
        is_accessToken: any
    ) => Promise<void>;
    partialSavedList: (
        configType: string,
        is_accessToken: string
    ) => Promise<void>;
    getSingleSavedClient: (id: string, accessToken: string, methodType: string) => Promise<void>
    submitPrediction: (client: Types.Client) => Promise<void>;
    getLocations: (client_code: string, selected_program: string, is_accessToken: any) => Promise<void>;
    getPcr: (client_code: string, selected_program: string) => Promise<void>;
    getPredictions: (client_code: string, selected_program: string, selected_name: string, is_prediction_screen: boolean, isPredictionScores: boolean) => Promise<void>;
    getProgramAndLikelihood: (client_code: string, selected_program: string) => Promise<void>;
    saveLocationAndProgram: (
        selected_location: string,
        pcr_score: any,
        roc_score: any,
        isPredictionScores: boolean
    ) => Promise<void>;
    saveProgramAndLikelihood: (
        selected_location: string,
        pcr_score: any,
        roc_score: any,
        isPredictionScores: boolean
    ) => Promise<void>;
    savePredictions: (
        clientCode: string,
        data: any,
        is_accessToken: string,
        isPredictionScores: boolean
    ) => Promise<void>;
    clearErrors: () => void;
    clearClient: () => void;
    // getAvailablePrograms: () => Promise<void>;
    getConfiguredQuestions: (is_accessToken: any) => Promise<void>;
    // getReferral: () => Promise<void>;
    logo: () => Promise<void>;
    Referral: Types.Referral[];
    isEdit: string;
    dynamicSearchFamily: (data: any, is_accessToken: string, version: number, keySearch: any) => void;
    saveSelectedFamiliesToYouth: (version: string, data: any, is_accessToken: string) => void;
}

export class DynamicNewClientContainer extends React.Component<
    DynamicNewClientContainerProp,
    DynamicNewClientContainerState
> {
    constructor(props: DynamicNewClientContainerProp) {
        super(props);
        this.state = this.getInitialState();
    }
    getInitialState() {
        let predictionScore = this.props.location?.state as PredictionScore;
        let modifyVersion = this.props.location?.state as ModifyVersion;

        return {
            isLoading: false,
            hasError: false,
            error: '',
            isSuccess: false,
            isPredictionScores: predictionScore?.isPredictionScores ? predictionScore?.isPredictionScores : false,
            isModifyVersion: modifyVersion?.isModifyVersion ? modifyVersion?.isModifyVersion : '',
            refferalInfo: 'Client',
            predictionResult: [],
            uniqueIdField: '',
            isSavedClientLoaded: false,
            softSavedRecord: [],
            tempDataId: '',
            progressMessage: '',
            isNoExcluCriteria: false,
            successMessage: ''
        };
    }

    GetQuestions = async () => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;

        try {
            await this.props.getConfiguredQuestions(is_accessToken);
        } catch (error) {
            console.log(error);
            const { history } = this.props;
            if (error.status === 403) {
                history.push(`/${domainPath}/logout/`);
            }
        }
    };

    GetSoftSaveList = async (callback: (result) => void) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;

        try {
            let list: any = await this.props.partialSavedList('1', is_accessToken);
            callback(list?.response);
        } catch (error) {
            console.log(error);
            const { history } = this.props;
            if (error.status === 403) {
                history.push(`/${domainPath}/logout/`);
            }
        }
    };

    async componentDidMount() {
        let refferalInfo: any = this.props.user && this.props.user.user.referral_info as referral_info;
        if (refferalInfo) {
            this.setState({
                refferalInfo: refferalInfo.referral_1
            })
            const {
                dynamicclient: questionsState
            } = this.props;
            let uniqueIdField: any = (questionsState && questionsState.uniqueIdField) || [];
            this.setState({ uniqueIdField: uniqueIdField })
        }
        this.props.match.params.isEdit === 'true' && this.props.closeSnackbar();
    }

    saveClientStep1 = async (client: Types.DynamicClient, action: string) => {
        const { history } = this.props;
        this.props.clearErrors();
        this.setState({ isLoading: true, progressMessage: 'FirstMatch is submitting new data...', error: '' });
        //this.props.saveClient(client, true, true);
        const orgType: any = this.props.user?.user?.org_type;
        if (this.state.tempDataId !== '') {
            client.temp_id = this.state.tempDataId;
        }
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        const res: any = await this.props.insertDClient(client, action, is_accessToken, this.state.isPredictionScores);
        const { index } = this.props.match.params;
        const { is_prediction_available }: any = this.props.user && this.props.user.user;


        if (res !== null && res.data?.status === 'success') {
            this.setState({ isLoading: false, error: '' });
            this.setState({ isSuccess: true, isSavedClientLoaded: false });
            let successMessage = this.state.isPredictionScores ? 'Submitted for Prediction' : res.data.message;

            if ((is_prediction_available && client['Exclusionary Criteria Exists/Referral Rejected']) && (client['Exclusionary Criteria Exists/Referral Rejected'] === '1' ||
                client['Exclusionary Criteria Exists/Referral Rejected'][0] === '1')) {
                this.setState({ isNoExcluCriteria: true })
            }

            if ((is_prediction_available && client.hasOwnProperty('Exclusionary Criteria Exists - Referral Immediately Rejected') && (client['Exclusionary Criteria Exists - Referral Immediately Rejected'] === '1' ||
                client['Exclusionary Criteria Exists - Referral Immediately Rejected'][0] === '1'))) {
                this.setState({ isNoExcluCriteria: true })
            }
            this.setState({ successMessage: successMessage })
        } else {
            this.setState({ isLoading: false, error: '' });
            if (res !== null && res.data?.message?.includes('not registered')) {
                this.props.enqueueSnackbar(`${this.state.refferalInfo} not registered. Please update the client outcomes.`);
            } else if (
                res !== null &&

                res.data?.message ===

                'Client Code already exists. Data is not saved. Please use the existing client search to update any existing client data.'
            ) {
                this.setState({
                    error: res.data.message
                });
            } else {
                this.setState({
                    error: Array.isArray(res.data?.response) ? res.data?.response : res.data?.message
                });
            }
        }
        return res.data;
    };

    redirectToNextStep = async () => {
        const { history } = this.props;
        const { index } = this.props.match.params;
        const { is_prediction_available }: any = this.props.user && this.props.user.user;
        const orgType: any = this.props.user?.user?.org_type;
        await this.props.enqueueSnackbar(this.state.successMessage);
        if (this.state.isNoExcluCriteria) {
            history.push({
                pathname: `/${domainPath}/new-client/dynamic-program-selection`,
                state: { isPredictionScores: this.state.isPredictionScores }
            })

        } else if (domainPath === 'demo') {
            this.state.isNoExcluCriteria
                ? history.push({
                    pathname: `/${domainPath}/new-client/program-selection`,
                    state: { isPredictionScores: this.state.isPredictionScores }
                })
                : history.push(`/${domainPath}/new-client/`);
        } else if (domainPath === 'county-demo') {
            history.push({
                pathname: `/${domainPath}/new-client/county-program-selection`,
                state: { isPredictionScores: this.state.isPredictionScores }
            });
        } else if (orgType && orgType === 3) {
            history.push({
                pathname: `/${domainPath}/new-client/fostercare-families`,
                state: { isPredictionScores: this.state.isPredictionScores }
            });
        } else {

            if (index) {
                history.push(`/${domainPath}/new-client/`);
            } else {
                window.location.reload();
            }
        }

    }

    softSaveClientStep = async (client: Types.DynamicClient) => {
        this.props.clearErrors();
        this.setState({ isLoading: true, error: '' });
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        if (this.state.tempDataId !== '') {
            client._id = this.state.tempDataId;
        }
        const res: any = await this.props.softSaveClient(client, is_accessToken);

        this.setState({ isLoading: false, error: '' });
        if (res !== null && res.data?.status === 'success') {
            this.setState({ isSuccess: true, isSavedClientLoaded: false, tempDataId: '' });
            this.props.enqueueSnackbar('Partial information of client/kid saved successfully. You may come back here to update information');
            window.location.reload();
        } else {
            this.props.enqueueSnackbar(res.data?.message ? res.data?.message : 'There is an error while saving information. Please try again.');
        }
        return res.data;
    };

    loadSavedClient = async (id: string, methodType: string, callback: CallbackType = () => { }) => {
        this.props.clearErrors();
        this.setState({ isLoading: true });
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        const res: any = await this.props.getSingleSavedClient(id, is_accessToken, methodType);
        this.setState({ isLoading: false });
        if (res !== null && res.hasOwnProperty('_id')) {
            this.setState({ isSavedClientLoaded: true, tempDataId: res?._id })
            this.props.enqueueSnackbar('Client information loaded successfully.');
            callback(true);
        } else if (res !== null) {
            this.props.enqueueSnackbar('Client information deleted successfully.');
            window.location.reload();
        }
        else {
            this.props.enqueueSnackbar('There is an error while saving information. Please try again.');
        }
        return res.data;
    };

    getLocationsAndPcr = async (selected_program: string) => {
        const { client: clientState } = this.props;
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        if (!clientState || !clientState.client) {
            return false;
        }
        this.setState({ isLoading: true });
        try {
            let client_code = clientState.client.hasOwnProperty('New Client Code') &&
                clientState.client['New Client Code'] !== '' ?
                clientState.client['New Client Code'] : clientState.client[`${this.state.uniqueIdField}`]
            await this.props.getPcr(client_code!, selected_program);
            //await this.props.getLocations(client_code!, selected_program, is_accessToken);
            this.setState({ isLoading: false });
        } catch (error) {
            this.setState({ isLoading: false });
        }


    };



    getProgramAndLikelihood = async (selected_program: string, selected_name: string) => {
        const { client: clientState } = this.props;
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        if (!clientState || !clientState.client) {
            return false;
        }
        this.setState({ isLoading: true });
        let client_code = clientState.client.hasOwnProperty('Identification Number') ? clientState.client['Identification Number'] : clientState.client.hasOwnProperty('New Client Code') &&
            clientState.client['New Client Code'] !== '' ?
            clientState.client['New Client Code'] : clientState.client[`${this.state.uniqueIdField}`]
        try {
            if (domainPath === 'county-demo') {
                await this.props.getProgramAndLikelihood(client_code!, selected_program);

            } else {
                let response: any = await this.props.getPredictions(client_code!, selected_program, selected_name, true, this.state.isPredictionScores);
                response?.message && this.props.enqueueSnackbar(`${response.message}`);
            }

            //await this.props.getLocations(client_code!, selected_program, is_accessToken);
            this.setState({ isLoading: false });
        } catch (error) {
            this.setState({ isLoading: false });
        }

    };

    savePredictions = async (data: string[] | any) => {
        const { client: clientState } = this.props;
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        if (!clientState || !clientState.client) {
            return false;
        }
        this.setState({ isLoading: true });
        let client_code = clientState.client.hasOwnProperty('Identification Number') ? clientState.client['Identification Number'] : clientState.client.hasOwnProperty('New Client Code') &&
            clientState.client['New Client Code'] !== '' ?
            clientState.client['New Client Code'] : clientState.client[`${this.state.uniqueIdField}`]
        this.setState({ isLoading: true });
        const result: any = await this.props.savePredictions(client_code, data, is_accessToken, this.state.isPredictionScores);

        this.setState({ isLoading: false });
        if (result?.status === 'success') {
            this.props.enqueueSnackbar('Report Generated');
        } else {
            this.props.enqueueSnackbar(result.message);
        }

        return result;
    };

    submitProgram = async (client: Types.Client) => {
        if (!client.client_code) {
            this.props.enqueueSnackbar('Error. Client information is required to process this form.');
            return false;
        }
        try {
            this.setState({ isLoading: true });
            await this.props.submitPrediction(client);
            this.setState({ isLoading: false });
        } catch (error) {
            let errorMessage: string = 'An error occurred while saving.';
            if (error['referred_program']) {
                errorMessage = error['referred_program'][0];
            } else if (error.message) {
                errorMessage = error.message;
            }
            this.props.enqueueSnackbar(errorMessage);
            this.setState({ isLoading: false });
        }

        //this.props.clearClient();
    };

    saveProgramAndLocation = async (selected_location: string, pcr_score: any, roc_score: any) => {
        const { client: clientState } = this.props;
        this.props.closeSnackbar();
        if (!clientState || !clientState.client) {
            this.props.enqueueSnackbar('Error. Client info not available.');
            return;
        }
        this.setState({ isLoading: true });
        try {
            await this.props.saveLocationAndProgram(selected_location, pcr_score, roc_score, this.state.isPredictionScores);
            this.setState({ isLoading: false });
            this.props.enqueueSnackbar('Report Generated');
        } catch (error) {
            this.setState({ isLoading: false });
            this.props.enqueueSnackbar('Server temporarily busy. Please try after some time.');
        }

    };

    saveProgramAndLikelihood = async (selected_location: string, pcr_score: any, roc_score: any, callBack: (result: boolean) => void) => {
        const { client: clientState } = this.props;
        this.props.closeSnackbar();
        if (!clientState || !clientState.client) {
            this.props.enqueueSnackbar('Error. Client info not available.');
            return;
        }
        this.setState({ isLoading: true });
        try {
            let response: any = await this.props.saveProgramAndLikelihood(selected_location, pcr_score, roc_score, this.state.isPredictionScores);
            if (response?.hasOwnProperty('result')) {
                callBack(true);
                this.props.enqueueSnackbar('Report Generated');
            } else {
                callBack(false);
                this.props.enqueueSnackbar('Server temporarily busy. Please try after some time.');
            }

            this.setState({ isLoading: false });

        } catch (error) {
            this.setState({ isLoading: false });
            this.props.enqueueSnackbar('Server temporarily busy. Please try after some time.');
        }

    };

    saveClientStep2 = async (client: Types.Client) => {
        try {
            this.setState({ isLoading: true });
            this.props.saveClient(client);
        } catch (error) {
            console.log(error);
            this.setState({ isLoading: false });
            this.props.enqueueSnackbar('An error occurred.' + error);
        }
    };

    dynamicSearchFamily = async (data: any, keySearch: any) => {
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        try {
            let response = await dynamicSearchFamily(data, is_accessToken);
        } catch (error) {
            console.log(error);
            const { history } = this.props;
            if (error.status === 403) {
                history.push(`/${domainPath}/logout/`);
            }
        }
    };

    saveSelectedFamiliesToYouth = async (data: any) => {
        const { history } = this.props;
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        const locationVersionComplete =
            this.props && this.props.location && (this.props.location.state as Types.CustomizedHistoryState);
        const locationVersion: string = locationVersionComplete.version;

        const version: any =
            locationVersion &&
            (parseInt(locationVersion.substring(1)) > 0
                ? parseInt(locationVersion.substring(1)) - 1
                : parseInt(locationVersion.substring(1)));
        const result: any = await this.props.saveSelectedFamiliesToYouth(version, data, is_accessToken);
        this.props.enqueueSnackbar(result.data.message);
        if (result.status === 200) {
            history.push(`/${domainPath}/new-client/`);
        }
    };

    render() {
        const {
            client: clientState,
            program: programState,
            referral: referralState,
            dynamicclient: questionsState
        } = this.props;
        const { isSavedClientLoaded } = this.state;
        const referralList = (referralState && referralState.referralList) || [];
        const clientList = (clientState && clientState.clientList) || {};
        let configuredQuestionsList: any = (questionsState && questionsState.configuredQuestionsList) || [];
        let partiallySavedQuestions: any = (questionsState && questionsState.partiallySavedQuestions) || [];
        const { index, Rerefer } = this.props.match.params;
        let currentClient: Types.Client;
        currentClient = clientState ? clientState.client : Types.emptyClient;
        const availableProgramList = (programState && programState.availableProgramList) || [];
        const is_accessToken: any = this.props.user && this.props.user.user.accessToken;
        partiallySavedQuestions = (partiallySavedQuestions.sections && partiallySavedQuestions.sections) || [];

        return (
            <Switch>
                <Route exact path={`/${domainPath}/new-client/county-program-selection`}>
                    <CountyProgramSelection
                        client={currentClient}
                        {...this.state}
                        onProgramSelect={this.getProgramAndLikelihood}
                        onLocationSelect={this.saveProgramAndLikelihood}
                        submitPrediction={this.submitProgram}
                        isLoading={this.state.isLoading}
                        programList={availableProgramList}
                    />
                </Route>
                <Route exact path={`/${domainPath}/new-client/program-selection`}>
                    <ProgramSelection
                        client={currentClient}
                        {...this.state}
                        onProgramSelect={this.getLocationsAndPcr}
                        onLocationSelect={this.saveProgramAndLocation}
                        submitPrediction={this.submitProgram}
                        isLoading={this.state.isLoading}
                        programList={availableProgramList}

                    />
                </Route>
                <Route exact path={`/${domainPath}/new-client/dynamic-program-selection`}>
                    <DynamicProgramSelection
                        client={currentClient}
                        {...this.state}
                        onProgramSelect={this.getProgramAndLikelihood}
                        onLocationSelect={this.savePredictions}
                        submitPrediction={this.submitProgram}
                        isLoading={this.state.isLoading}
                        programList={availableProgramList}
                        DynamicQuestions={configuredQuestionsList.filter((sec) => sec.section === 'Outcomes')}
                        errors={this.state.error}
                        isEdit={index ? 'true' : 'false'}
                        isPredictionScores={this.state.isPredictionScores}

                    />
                </Route>
                <Route
                    exact
                    path={`/${domainPath}/new-client/2`}
                    render={(routeProps) => {
                        return (
                            <PredictionFormStep2
                                {...this.state}
                                {...routeProps}
                                client={currentClient}
                                onFormSubmit={this.saveClientStep2}
                                errors={(clientState && clientState.errors) || undefined}
                            />
                        );
                    }}
                ></Route>
                <Route
                    exact
                    path={`/${domainPath}/new-client/fostercare-families`}
                    render={(routeProps) => {
                        return (
                            <BasicRequirementStep1
                                {...this.state}
                                {...routeProps}
                                client={currentClient}
                                onFormSubmit={this.saveSelectedFamiliesToYouth}
                                errors={(clientState && clientState.errors) || undefined}
                                onSearchFamily={this.dynamicSearchFamily}
                                is_accessToken={is_accessToken}
                            />
                        );
                    }}
                ></Route>
                <Route
                    exact
                    path={
                        index
                            ? `/${domainPath}/existing-client/edit-details/:index&:isEdit&:Rerefer`
                            : `/${domainPath}/new-client`
                    }
                >
                    <PredictionFormStep
                        {...this.state}
                        isEdit={index ? true : false}
                        client_id={index ? clientList[index]._id : ''}
                        reReffer={Rerefer ? Rerefer : ''}
                        Referral={referralList}
                        user={this.props && this.props.user}
                        DynamicQuestions={
                            index
                                ? [
                                    ...clientList[index].sections.filter((sec) => sec.section !== 'Outcomes'),
                                    ...configuredQuestionsList.filter((sec) => sec.section === 'Re Type')
                                ]
                                : isSavedClientLoaded ? partiallySavedQuestions.filter((sec: any) => sec.section !== 'Outcomes')
                                    : configuredQuestionsList.filter((sec) => sec.section !== 'Outcomes')
                        }
                        //client={currentClient.model_program ? Types.emptyClient : currentClient}
                        onFormSubmit={this.saveClientStep1}
                        onFormSave={this.softSaveClientStep}
                        GetQuestions={this.GetQuestions}
                        GetSoftSaveList={this.GetSoftSaveList}
                        getSingleSavedClient={this.props.getSingleSavedClient}
                        loadSavedClient={this.loadSavedClient}
                        errors={this.state.error}
                        kidCode={index}
                        isSavedClientLoaded={isSavedClientLoaded}
                        redirectToNextStep={this.redirectToNextStep}
                    />
                </Route>
            </Switch>
        );
    }
}

const mapStateToProps = (state: AppState) => {
    return {
        client: state.client,
        program: state.program,
        referral: state.referral,
        user: state.user,
        dynamicclient: state.dynamicclient
    };
};

const mapDispatchToProps = {
    saveClient: client.actions.upsertClient,
    insertDClient: dynamicclient.actions.insertDClient,
    softSaveClient: dynamicclient.actions.softSaveClient,
    partialSavedList: dynamicclient.actions.partialSavedList,
    getSingleSavedClient: dynamicclient.actions.getSingleSavedClient,
    submitPrediction: dynamicclient.actions.submitPrediction,
    getLocations: dynamicclient.actions.getLocations,
    getPcr: dynamicclient.actions.getPcr,
    getPredictions: dynamicclient.actions.getPredictions,
    getProgramAndLikelihood: dynamicclient.actions.getProgramAndLikelihood,
    saveLocationAndProgram: dynamicclient.actions.saveLocationAndProgram,
    saveProgramAndLikelihood: dynamicclient.actions.saveProgramAndLikelihood,
    savePredictions: dynamicclient.actions.savePredictions,
    clearErrors: client.actions.clearErrors,
    clearClient: client.actions.clear,
    // getAvailablePrograms: program.actions.getAvailablePrograms,
    getConfiguredQuestions: dynamicclient.actions.getConfiguredQuestions,
    dynamicSearchFamily: family.actions.dynamicSearchFamily,
    saveSelectedFamiliesToYouth: dynamicclient.actions.saveSelectedFamiliesToYouth
};

export default connect(mapStateToProps, mapDispatchToProps)(withSnackbar(DynamicNewClientContainer));
